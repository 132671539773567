import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getEvent, createEvent, editEvent } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";

const CreateEvent = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    // const [memberList, setMemberList] = useState([]);
    // const [memberListFilter, setMemberListFilter] = useState([]);
    // const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `+${country[item].callingCode[0] ?? ""}` } }).filter((c) => c.value !== ""));
    const history = useHistory();
    const [file, setFile] = useState([]);
    const [data, setData] = useState({
        // fullName: "",
        // email: "",
        // username: "",
        // phoneCode: "",
        // phone: "",
        // pin: "",
        // confirmPin: "",
        eventID: "",
        eventName: "",
        eventDescription: "",
        eventDateStart: "",
        eventDateEnd: "",
        eventImage: "",
    });
    const [error, setError] = useState({
        // fullName: "",
        // email: "",
        // username: "",
        // phoneCode: "",
        // phone: "",
        // confirmPin: "",
        eventName: "",
        eventDescription: "",
        eventDateStart: "",
        eventDateEnd: "",
        eventImage: "",
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                // fullName: "",
                // email: "",
                // username: "",
                // gender: "",
                // phoneCode: "",
                // phone: "",
                // pin: "",
                // confirmPin: "",
                eventID: "",
                eventName: "",
                eventDescription: "",
                eventDateStart: "",
                eventDateEnd: "",
                eventImage: "",
            })
        } else if (type === 'view') {
            if (!slug) {
                history.push('/event');
                return;
            }
            // getDetails(slug);
            // getMemberList(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/event');
                return;
            }
            getDetails(slug);
            // getMemberList(slug);
        }
    }, [])

    let inputEventStartProps = {
        placeholder: translate(lang, 'eventStart'),
        name: "eventDateStart",
    };

    let inputEventEndProps = {
        placeholder: translate(lang, 'eventEnd'),
        name: "eventDateEnd",
    };

    // const listViewLayout = (list) => {
    //     return (
    //         <>
    //             <div className="card d-flex flex-column agent-member-container-listview">
    //                 {list.map(i => <>
    //                     <div className="d-flex flex-column flex-wrap px-3 py-3">
    //                         <div className="d-flex flex-column flex-md-row flex-wrap py-1">
    //                             <div className=""><b>{translate(lang, "fullName")}</b>: {i.fullName}</div>
    //                             <div className="mx-2"><b>{translate(lang, "age")}</b>: {i.age}</div>
    //                             <div className="mx-2"><b>{translate(lang, "identityCard")}</b>: {i.identityCard && i.identityCard !== "" ? i.identityCard : "-"}</div>
    //                         </div>
    //                         <div className="d-flex flex-column flex-md-row flex-wrap">
    //                             <div className=""><b>{translate(lang, "phone")}</b>: {i.phoneCode} {i.phone}</div>
    //                             <div className="mx-2"><b>{translate(lang, "email")}</b>: {i.email && i.email !== "" ? i.email : "-"}</div>
    //                         </div>
    //                     </div>
    //                 </>)}
    //             </div>
    //         </>
    //     )
    // }

    // const onFilterInputChange = (input) => {
    //     // console.log('form', input);
    //     let temp;
    //     if (input !== "") {
    //         temp = memberList.filter(a => {
    //             let keys = Object.keys(a);
    //             let regex = new RegExp(input, "");
    //             if (keys.find(k => a[k] && a[k].toString().match(regex) && a[k].toString().match(regex).length > 0)) {
    //                 return a;
    //             }
    //             // console.log(a, keys);
    //             // keys.forEach(k => console.log(a[k], input))
    //             // return keys.forEach(k => {
    //             //     if (a[k]) {
    //             //         console.log(a[k].toString(), input.toString())
    //             //         if (a[k].toString().match(regex)) {
    //             //             return a
    //             //         }
    //             //     } else {
    //             //             console.log('no and skip next')
    //             //     }
    //             // })
    //             // return keys.forEach(k => a[k] && a[k].indexOf(input) > -1 ? a : false)
    //             // return passed ? a : false
    //         })
    //     } else {
    //         temp = memberList;
    //     }
    //     setMemberListFilter([...temp])
    // }

    const getDetails = async (slug) => {
        // let params = { id: slug };
        let res = await getEvent(slug);

        if (res.status === 'success') {
            let data = res.data;
            setData({
                eventID: data.id,
                eventName: data.title,
                eventDescription: data.description,
                eventDateStart: moment(data.event_start).format(translate(lang, "date_time_format")),
                eventDateEnd: moment(data.event_end).format(translate(lang, "date_time_format")),
                eventImage: data.image,
            });
        } else {
            history.push('/event');
        }
    }

    // const getMemberList = async (slug) => {
    //     let params = { assignedAgent: slug, basic: 1 };
    //     let res = await getUserList(params);
    //     if (res.status === 'success') {
    //         let data = res.data;
    //         setMemberList([...data]);
    //         setMemberListFilter([...data]);
    //     } else {
    //         console.log(res);
    //     }
    // }

    const onDateChange = (e, _props) => {
        if (type === 'view') return;
        if (e._d) {
            const convertedDate = moment(e._d).format(translate(lang, "date_time_format"));
            let temp = data;
            temp[_props.name] = convertedDate;
            setData({ ...temp });
        }
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };


    const onFileChange = (event) => {
        setFile(event.target.files);
    };

    // const handleOptionSelected = (e, name) => {
    //     const { value } = e;
    //     const list = { ...data };
    //     list[name] = value;
    //     setData(list);
    // }

    const submitForm = async () => {
        let check = true;
        let error = {
            // fullName: "",
            // phone: "",
            // email: "",
            // gender: "",
            // pin: "",
            // confirmPin: "",
            eventName: "",
            eventDescription: "",
            eventDateStart: "",
            eventDateEnd: "",
            eventImage: "",
        }

        if (!data.eventName) {
            check = false;
            error.eventName = translate(lang, "errorRequired");
        }
        if (!data.eventDescription) {
            check = false;
            error.eventDescription = translate(lang, "errorRequired");
        }
        if (!data.eventDateStart) {
            check = false;
            error.eventDateStart = translate(lang, "errorRequired");
        }
        if (!data.eventDateEnd) {
            check = false;
            error.eventDateEnd = translate(lang, "errorRequired");
        }

        // if (!data.eventImage) {
        //     check = false;
        //     error.eventImage = translate(lang, "errorRequired");
        // }

        setError({ ...error });
        if (check) {
            const formData = new FormData();
            formData.append("name", data.eventName);
            formData.append("description", data.eventDescription);
            formData.append("date_start", data.eventDateStart);
            formData.append("date_end", data.eventDateEnd);
            formData.append("merchant_id", merchant_id);

            if (file && file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    formData.append("image", file[i]);
                }
            }

            if (type == 'create') {
                await createEvent(formData).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/event');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type == 'edit') {
                await editEvent(formData, data.eventID).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/event');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    // const resetForm = () => {
    //     setData({
    //         // fullName: "",
    //         // email: "",
    //         // username: "",
    //         // phoneCode: "",
    //         // phone: "",
    //         // pin: "",
    //         // confirmPin: "",
    //         eventName: "",
    //         eventDescription: "",
    //         eventDateStart: "",
    //         eventDateEnd: "",
    //         eventImage: "",
    //     })
    // }

    return (
        <>
            <div className="d-flex flex-column flex-md-row ">
                <div className="col-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "detailsEvent" : type === "edit" ? "editEvent" : "createEvent"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">
                            <div className="form-group py-2">
                                <div>
                                    <label className="form-label">{translate(lang, 'eventImage')}</label>
                                    <input type="file" name="eventImage" className="form-control h-100" accept="image/*" onChange={(e) => onFileChange(e)} />
                                    <div className="">
                                        {(file.length == 0 && data.eventImage)
                                            ?
                                            <img src={(type == 'edit') ? data.eventImage : logo512} width={200} height={200} className="w-image-fluid mt-3" />
                                            : <></>
                                        }
                                        {/* <button className="btn btn-danger btn-xs ms-3">{translate(lang, 'Delete')}</button> */}
                                    </div>
                                    <label className="error">{error.eventImage}</label>
                                </div>
                            </div>
                            <div className="form-group py-2 required">
                                <label className="form-label">{translate(lang, 'eventName')}</label>
                                <input type="text" className="form-control" name="eventName" value={data.eventName} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                <label className="text-danger">{error.eventName}</label>
                            </div>
                            <div className="form-group py-2 required">
                                <label className="form-label">{translate(lang, 'description')}</label>
                                <textarea className="form-control" name="eventDescription" value={data.eventDescription} rows={5} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')}></textarea>
                                <label className="text-danger">{error.eventDescription}</label>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group py-2 col-12 col-md-6 required">
                                    <label className="form-label">{translate(lang, 'eventStart')}</label>
                                    <Datetime dateFormat="YYYY-MM-DD" timeFormat="HH:mm" value={data.eventDateStart} inputProps={inputEventStartProps} onChange={(e) => onDateChange(e, inputEventStartProps)} />
                                    <label className="text-danger">{error.eventDateStart}</label>
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 ps-2 required">
                                    <label className="form-label">{translate(lang, 'eventEnd')}</label>
                                    <Datetime dateFormat="YYYY-MM-DD" timeFormat="HH:mm" value={data.eventDateEnd} inputProps={inputEventEndProps} onChange={(e) => onDateChange(e, inputEventEndProps)} />
                                    <label className="text-danger">{error.eventDateEnd}</label>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/event')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {type === "view" ?
                    (<div className="col-12 col-md-6 ps-3">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="m-0">
                                    {translate(lang, "assignedMemberList")}
                                </h3>
                            </div>
                            <div className="card-body col-12 d-flex flex-column h-25-vh">
                                {memberList.length > 0 ?
                                    <ListView data={memberListFilter} onFilterInputChange={(input) => onFilterInputChange(input)} layout={listViewLayout(memberListFilter)} search={true} lang={props.lang}></ListView>
                                    : <p>{translate(lang, "noMemberAssignedToThisAgent")}</p>
                                }
                            </div>
                        </div>
                    </div>)
                    : <></>
                } */}
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CreateEvent);