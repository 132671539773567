import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getProductList, getCategoryList } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { Table } from "reactstrap";
import ReactPaginate from 'react-paginate';
import logo512 from "../../../images/logo512.png";
import { Accordion } from "react-bootstrap";

const Custody = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingData, setLoadingData] = useState(false);
    const [filter, setFilter] = useState({
        filter_name: "",
        filter_code: "",
        filter_category: ""
    });

    const inputOnchange = (e) => {
        const tmp = filter;
        tmp[e.target.name] = e.target.value;
        setFilter({ ...tmp });
    };

    const submitFilter = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        getList();
    }

    const [data, setData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);

    useEffect(() => {
        getList()
    }, [currentPage])

    useEffect(() => {
        getCategories();
    }, [])

    async function getList() {
        setLoadingData(true);
        let query = {
            merchant_id: merchant_id,
            name: filter.filter_name,
            code: filter.filter_code,
            category: filter.filter_category,
            // status: filter.filter_status,
            page: currentPage,
            limit: 36
        };

        let res = await getProductList(query);

        if (res?.status === 'success') {
            setData(res.data)
            // setFileData([...res.data])
        } else {
        }
        setLoadingData(false);
    }

    async function getCategories() {
        let query = {
            merchant_id: merchant_id,
        };

        let res = await getCategoryList(query);

        if (res?.status === 'success') {
            setCategoryData(res.data)
            // setFileData([...res.data])
        } else {
        }
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };

    const pageCount = Math.ceil(data.total_results / 36);

    const onClickProduct = (event, product_id) => {
        if (product_id) {
            history.push(`/custody/create/${product_id}`);
        }
    };

    const [activeAccordionWithIcon, setActiveAccordionWithIcon] = useState(0);

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Accordion
                        className="accordion accordion-with-icon"
                        defaultActiveKey="0"
                    >
                        <div className="accordion-item" key={1}>
                            <Accordion.Toggle
                                as={`div`}
                                eventKey={`1`}
                                className={`accordion-header ${activeAccordionWithIcon === 1 ? "" : "collapsed"
                                    }`}
                                onClick={() =>
                                    setActiveAccordionWithIcon(
                                        activeAccordionWithIcon === 1 ? -1 : 1
                                    )
                                }
                            >
                                {/* <span className="accordion-header-icon"></span> */}
                                <span className="accordion-header-text ps-0"><i className="fas fa-filter"></i> {translate(lang, "btnFilter")}</span>
                                <span className="accordion-header-indicator indicator-bordered"></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`1`}>
                                <div className="accordion-body-text">
                                    <div className="header-filter">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="filter-name">{translate(lang, 'productName')}</label>
                                                    <input id="filter-name" type="text" name="filter_name" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'productName')}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="filter-code">{translate(lang, 'productCode')}</label>
                                                    <input id="filter-code" type="text" name="filter_code" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'productCode')}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="filter-category">{translate(lang, 'category')}</label>
                                                    <select className="form-control" name="filter_category" onChange={(e) => inputOnchange(e)}>
                                                        <option value="">{translate(lang, 'pleaseSelect')}</option>
                                                        {(categoryData && categoryData.length > 0)
                                                            ?
                                                            categoryData.map((value) => (
                                                                <option key={value.id} value={value.name}>{value.name}</option>
                                                            ))
                                                            :
                                                            <></>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-filter-button text-end">
                                            <button className="btn btn-primary btn-md mt-3" onClick={(e) => submitFilter(e)}>{translate(lang, 'btnFilter')}</button>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        <h3>{translate(lang, "selection")}</h3>
                        <div className="header-button my-4">
                            {/* <div className="float-start"> */}
                            <Link to="/create_custody" className="btn btn-primary btn-sm">{translate(lang, 'createCustody2')}</Link>
                            {/* </div> */}
                        </div>
                    </div>
                    {(loadingData) ?
                        <>
                            <div className="loader-div"><i className="fas fa-spinner fa-spin"></i></div>
                        </>
                        :
                        <>
                            <div className="row product-row">
                                {(data && data.results && data.results.length > 0)
                                    ?
                                    data.results.map((value) => (
                                        <div className="col-lg-2 col-md-3 col-sm-4 col-6 product-template-col" key={value.id}>
                                            <div className="product-template-box mb-5" onClick={(e) => { onClickProduct(e, value.id) }}>
                                                <div className="product-template-image text-center position-relative">
                                                    {(value.default_prod_image)
                                                        ?
                                                        <img src={value.default_prod_image} alt={value.name} className="w-image-fluid" />
                                                        :
                                                        <img src={logo512} alt={value.name} className="w-image-fluid" />
                                                    }

                                                    {(value.category_1)
                                                        ?
                                                        <div className="product-category position-absolute">
                                                            <span>{value.category_1}</span>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </div>

                                                <div className="product-template-desc pt-1">
                                                    <span>{value.code}</span>
                                                    <br />
                                                    <span>{value.description}</span>
                                                </div>

                                            </div>
                                            {/* <td scope="row">{value.name}</td>
                                    <td scope="row">{value.code}</td>
                                    <td scope="row">{value.category_1}</td>
                                    <td scope="row"><a className="btn btn-xs btn-primary" href={`/product/edit/${value.id}`}><i className="fas fa-pencil-alt"></i></a></td> */}
                                        </div>
                                    ))
                                    :
                                    <div className="text-center">{translate(lang, 'noResult')}</div>
                                }
                            </div>

                            {(data && data.results && data.results.length > 0)
                                ?
                                <ReactPaginate
                                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                    previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                    nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                    disabledClassName={"pagination__link--disabled text-muted"}
                                    activeClassName={"pagination__link--active text-white"}
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    forcePage={currentPage - 1}
                                />
                                : <></>}
                        </>
                    }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(Custody);