import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getProduct, getCustomerAutoComplete, createCustody, getCustody, getMISC, getPhoneCode } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
// import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";
import Autocomplete from 'react-autocomplete';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const CreateCustody = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    const history = useHistory();

    const [data, setData] = useState({
        productID: "",
        productImage: "",
        productImageDefaultValue: "",
        productCategory: "",
        productCode: "",
        productName: "",
        productUOM: "",
        productUOMDesc: "",
        productDefaultExpiryRange: "",
        productDefaultExpiryUnit: "",
    });
    const [error, setError] = useState({
        custodyCode: "",
        customer: "",
        phone: "",
        balance: "",
        custodyDate: "",
        expiryPeriod: "",
    });

    const [loadingData, setLoadingData] = useState(false);

    const today = new Date();
    // Format today's date to YYYY-MM-DD
    const defaultDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [postData, setPostData] = useState({
        // customerID: "",
        name: "",
        phoneCode: "60",
        phone: "",
        email: "",
        customerReference: "",
        remark_1: "",
        remark_2: "",
        remark_3: "",
        balance: "",
        custodyDate: dayjs(defaultDate),
        expireType: 1,
        expiryUnit: "",
        expirySelection: "days",
        expiryDate: "",
        custodyCode: "",
        customerName: "",
        transactionType: "",
        transactionDate: "",
        createdBy: "",
    });

    const [pageType, setPageType] = useState();

    const [expiryType, setExpiryType] = useState();

    useEffect(() => {
        setExpiryType(1);
        getMISCDetails();

        if (type === 'create') {
            if (slug) {
                getDetails(slug);
            } else {
                history.push('/custody_list');
            }

        } else if (type === 'edit') {
            history.push('/custody_list');

            // if (!slug) {
            //     history.push('/product');
            //     return;
            // }
            // getDetails(slug);
        } else {
            if (!type) {
                history.push('/custody_list');
            }
            const slug_arr = type.split("_");

            if (slug_arr) {
                if (slug_arr.length != 2) {
                    history.push('/custody_list');
                }
            } else {
                history.push('/custody_list');
            }
            setPageType("view");
            getCustodyDetails(slug_arr[1], slug);
        }
    }, [])

    const getCustodyDetails = async (type, slug) => {
        setLoadingData(true);

        let query = {
            type
        }
        let res = await getCustody(query, slug);

        if (res.status === 'success') {
            let data = res.data;

            setData({
                productImage: data.image_path,
                productCategory: data.prod_cat_1,
                productCode: data.prod_code,
                productName: data.prod_desc,
                productUOM: data.prod_uom,
                productUOMDesc: data.prod_uom_desc,
            });

            setPostData({
                custodyCode: data.code,
                customerName: data.customer_name,
                phone: data.customer_phone,
                email: data.customer_email,
                customerReference: (data.customer_reference) ? data.customer_reference : "",
                remark_1: (data.remark) ? data.remark : "",
                remark_2: (data.remark2) ? data.remark2 : "",
                remark_3: (data.remark3) ? data.remark3 : "",
                balance: (data.net_balance !== undefined) ? data.net_balance : data.balance,
                custodyDate: moment(data.custody_date).format(translate(lang, 'date_format')),
                expiryDate: (data.expiry_type == 'never expired') ? data.expiry_type : moment(data.expiry_date).format(translate(lang, 'date_format')),
                transactionType: `${data.type} ${data.description}`,
                transactionDate: moment(data.created_date).format(translate(lang, 'date_time_format')),
                createdBy: data.created_by_info.display_name,
            })

            // let tmp_post_data = postData;

            // tmp_post_data['expiryUnit'] = (data.default_expiry_unit) ? data.default_expiry_unit : "";
            // tmp_post_data['expirySelection'] = (data.default_expiry_range) ? data.default_expiry_range : "days";

            // setPostData({ ...tmp_post_data });

            setLoadingData(false);

        } else {
            history.push('/custody');
        }
    }

    const getDetails = async (slug) => {
        let res = await getProduct(slug);

        if (res.status === 'success') {
            let data = res.data;

            setData({
                productID: data.id,
                productImage: data.default_prod_image,
                productImageDefaultValue: "",
                productCategory: data.category_1,
                productCode: data.code,
                productName: data.description,
                productUOM: data.uom,
                productUOMDesc: data.uom_desc,
                productDefaultExpiryRange: data.default_expiry_range,
                productDefaultExpiryUnit: data.default_expiry_unit,
            });

            let tmp_post_data = postData;

            tmp_post_data['expiryUnit'] = (data.default_expiry_unit) ? data.default_expiry_unit : "";
            tmp_post_data['expirySelection'] = (data.default_expiry_range) ? data.default_expiry_range : "days";

            setPostData({ ...tmp_post_data });

        } else {
            history.push('/custody');
        }
    }

    const onInputChange = (event) => {
        const { value, name } = event.target;
        let temp = postData;
        temp[name] = value;
        setPostData({ ...temp });
    };

    const submitForm = async () => {
        let check = true;
        let error = {
            custodyCode: "",
            customer: "",
            phone: "",
            balance: "",
            custodyDate: "",
            expiryPeriod: "",
        }

        // if (!postData.customerID) {
        //     check = false;
        //     error.customer = translate(lang, "errorRequired");
        // }

        if (!postData.name) {
            check = false;
            error.customer = translate(lang, "errorRequired");
        }

        if (!postData.phone) {
            check = false;
            error.phone = translate(lang, "errorRequired");
        }

        if (!postData.phoneCode) {
            check = false;
            error.phone = translate(lang, "errorRequired");
        }

        if (postData.balance <= 0) {
            check = false;
            error.balance = translate(lang, "errorRequired");
        }

        if (postData.expireType == 1) {
            if (postData.expiryUnit <= 0) {
                check = false;
                error.expiryPeriod = translate(lang, "errorRequired");
            }
        } else if (postData.expireType == 2) {
            if (!postData.expiryDate) {
                check = false;
                error.expiryPeriod = translate(lang, "errorRequired");
            } else {
                if (postData.expiryDate <= postData.custodyDate) {
                    check = false;
                    error.expiryPeriod = translate(lang, "errorExpiryDate");
                }
            }
        }

        setError({ ...error });
        if (check) {

            const tmp_post_data = {
                // "customer_id": postData.customerID,
                "name": postData.name,
                "merchant_id": merchant_id,
                "phoneCode": postData.phoneCode,
                "phonenumber": postData.phone,
                "email": postData.email,
                "reference": postData.customerReference,
                "remark_1": postData.remark_1,
                "remark_2": postData.remark_2,
                "remark_3": postData.remark_3,
                "product_id": data.productID,
                "balance": postData.balance,
                "custody_date": postData.custodyDate.format("YYYY-MM-DD"),
                "expiry_type": "",
                "expiry_date": "",
                "expiry_unit": "",
                "expiry_selection": "",
                "sms_flag": (isChecked.default_sms) ? "1" : "0",
            }

            let post_data = tmp_post_data;

            if (postData.expireType == 1) {
                const expiryDate = postData.custodyDate.add(postData.expiryUnit, postData.expirySelection).format("YYYY-MM-DD");
                post_data['expiry_type'] = `expired after ${expiryDate}`;
                post_data['expiry_date'] = expiryDate;
                post_data['expiry_unit'] = postData.expiryUnit;
                post_data['expiry_selection'] = postData.expirySelection;
            } else if (postData.expireType == 2) {
                const expiryDate = postData.expiryDate.format("YYYY-MM-DD");
                post_data['expiry_type'] = `expired on ${expiryDate}`;
                post_data['expiry_date'] = expiryDate;
                post_data['expiry_unit'] = '';
                post_data['expiry_selection'] = '';
            } else if (postData.expireType == 3) {
                post_data['expiry_type'] = 'never expired';
                post_data['expiry_date'] = '0000-00-00 00:00:00';
                post_data['expiry_unit'] = '';
                post_data['expiry_selection'] = '';
            }

            if (type == 'create') {
                await createCustody(post_data).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        if (res.data) {
                            if (res.data.premium_sms !== undefined && res.data.premium_sms === false) {
                                window.open(`sms:+${res.data.customer_phone}?body=${res.data.sms_body}`);
                                history.push('/custody_list');
                            } else {
                                history.push('/custody_list');
                            }
                        } else {
                            history.push('/custody_list');
                        }
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type == 'edit') {
                // await editProduct(formData, data.productID).then((res) => {
                //     if (res.status === 'success') {
                //         promptMessage(lang, 'success', res.message)
                //         history.push('/product');
                //     } else {
                //         promptMessage(lang, 'error', res.message)
                //     }
                // }).catch((error) => {
                //     promptMessage(lang, 'error', error);
                // })
            }
        }
    }

    // AUTOCOMPLETE START
    const [autoCompleteDatas, setAutoCompleteData] = useState([]);
    const [showRemoveAutoCompleteValue, setShowRemoveAutoCompleteValue] = useState(false);
    const [autocompleteValue, setAutoCompleteValue] = useState({
        filter_name: "",
        filter_product_input: ""
    });

    const timerRef = useRef(null);

    async function autoComplete() {
        let query = { input: autocompleteValue.filter_name, limit: 5 }

        let res = await getCustomerAutoComplete(query);

        if (res?.status === 'success') {
            setAutoCompleteData(res.data)
        } else {
        }
    }

    const autocompleteOnChange = (e, type) => {
        const tmp = autocompleteValue;
        tmp[e.target.name] = e.target.value;
        setAutoCompleteValue({ ...tmp });

        if (!e.target.value) {
            setAutoCompleteData([]);
        }

        if (type == 'customer') {
            if (autocompleteValue.filter_name) {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    autoComplete();
                }, 500)
            }

            let tmp_data = postData;
            tmp_data.name = autocompleteValue.filter_name;
            setPostData({ ...tmp_data });
        }
    }

    const autocompleteOnSelect = (e, value) => {
        let tmp_data = postData;

        tmp_data['phone'] = (value.phonenumber) ? value.phonenumber : "";
        tmp_data['phoneCode'] = (value.phonecode) ? value.phonecode : "";
        tmp_data['email'] = (value.email) ? value.email : "";
        // tmp_data['customerID'] = (value.id) ? value.id : "";
        tmp_data['name'] = (value.name) ? value.name : "";

        setPostData({ ...tmp_data });

        setAutoCompleteValue({ filter_name: value.name });
        setShowRemoveAutoCompleteValue(true);
        setAutoCompleteData([]);
    }

    const menuStyle = {
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        position: 'absolute',
        left: '0px',
        top: '40px',
    };

    const autoCompleteClose = (e) => {
        e.preventDefault();

        const tmp = autocompleteValue;
        tmp['filter_name'] = e.target.value;
        setAutoCompleteValue({ ...tmp });

        let tmp_data = postData;

        tmp_data['phone'] = "";
        tmp_data['phoneCode'] = "60";
        tmp_data['email'] = "";
        // tmp_data['customerID'] = "";
        tmp_data['name'] = "";

        setPostData({ ...tmp_data });

        setAutoCompleteData([]);
        setShowRemoveAutoCompleteValue(false);
    }

    // AUTOCOMPLETE END

    const onDateChange = (e, name) => {
        if (e) {
            // let tmp_date = datePickerDate;
            // tmp_date[name] = e;
            // setDatePickerDate({ ...tmp_date });

            const convertedDate = dayjs(e.$d).format(translate(lang, 'date_format'));
            let temp = postData;
            temp[name] = e;
            setPostData({ ...temp });
        }
    }

    const onchangeExpireType = (e) => {
        setExpiryType(e.target.value);

        let tmp_post_data = postData;

        tmp_post_data['expireType'] = e.target.value;

        setPostData({ ...tmp_post_data });
    }

    const [isChecked, setIsChecked] = useState({
        "custody_out_quantity": false,
        "approval_code": false,
        "premium_sms": false,
        "enable_qr": false,
        "default_sms": false,
        "lock_expiry_period": false
    });

    const getMISCDetails = async (slug) => {
        let res = await getMISC();

        if (res.status === 'success') {
            let tmp_data = res.data;

            setIsChecked({
                "custody_out_quantity": (tmp_data.custody_out_quantity) ? tmp_data.custody_out_quantity : false,
                "approval_code": (tmp_data.approval_code) ? tmp_data.approval_code : false,
                "premium_sms": (tmp_data.premium_sms) ? tmp_data.premium_sms : false,
                "enable_qr": (tmp_data.enable_qr) ? tmp_data.enable_qr : false,
                "default_sms": (tmp_data.default_sms) ? tmp_data.default_sms : false,
                "lock_expiry_period": (tmp_data.lock_expiry_period) ? tmp_data.lock_expiry_period : false,
            })

        } else {
            // history.push('/settings/misc');
        }
    }

    const checkboxClick = (e) => {
        const tmp_data = isChecked;
        tmp_data.default_sms = !isChecked.default_sms;

        setIsChecked({ ...tmp_data });
    }

    useEffect(() => {
        getPhoneCodeList();
    }, [])

    const [phoneCodeData, setPhoneCodeData] = useState([]);

    async function getPhoneCodeList() {
        let res = await getPhoneCode();

        if (res?.status === 'success') {
            setPhoneCodeData(res.data);
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row ">
                <div className="col-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (pageType === "view" ? "viewCustody" : "createCustody"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">
                            {(loadingData)
                                ?
                                <>
                                    <div className="loader-div loader-lg-div"><i className="fas fa-spinner fa-spin"></i></div>
                                </>
                                :
                                <></>
                            }
                            {(pageType == 'view')
                                ?
                                <>
                                    <div className="form-group row mb-4">
                                        <label className="col-sm-2 col-form-label">{translate(lang, 'transactionType')}</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" value={postData.transactionType} readOnly />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-sm-2 col-form-label">{translate(lang, 'transactionDate')}</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" value={postData.transactionDate} readOnly />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-sm-2 col-form-label">{translate(lang, 'createdBy')}</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" value={postData.createdBy} readOnly />
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                            }
                            <div className="form-group row mb-4">
                                <label className="col-sm-2 col-form-label">{translate(lang, 'item')}</label>
                                <div className="col-sm-10">
                                    <div className="d-flex">
                                        <img src={(data.productImage) ? data.productImage : logo512} width={100} height={100} className="w-image-fluid me-3" />
                                        <span>{data.productCode}<br />{data.productName}</span>
                                    </div>
                                </div>
                            </div>
                            {(type != 'create')
                                ?
                                <div className="form-group row mb-4">
                                    <label htmlFor="input-custody-code" className="col-sm-2 col-form-label">{translate(lang, 'custodyCode')}</label>
                                    <div className="col-sm-10">
                                        <input id="input-custody-code" type="text" className="form-control" name="custodyCode" value={postData.custodyCode} readOnly />
                                        {(error.custodyCode)
                                            ?
                                            <label className="text-danger">{error.custodyCode}</label>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                                :
                                <></>
                            }
                            <div className="form-group row mb-4 required">
                                <label htmlFor="input-customer" className="col-sm-2 col-form-label form-label">{translate(lang, 'customer')}</label>
                                <div className="col-sm-10 position-relative">
                                    {(pageType == 'view')
                                        ?
                                        <input type="text" value={postData.customerName} className="form-control" readOnly />
                                        :
                                        <>
                                            <Autocomplete
                                                items={autoCompleteDatas}
                                                getItemValue={(item) =>
                                                    item.name
                                                }
                                                renderItem={(item, isHighlighted) =>
                                                    <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: "5px 7px" }} key={item.id}>
                                                        {item.name} {item.phonenumber ? '- ' + item.phonecode + item.phonenumber : <></>}
                                                    </div>
                                                }
                                                value={autocompleteValue.filter_name} // Initially, no value is set
                                                onChange={(e) => autocompleteOnChange(e, "customer")}
                                                onSelect={(value, item) => autocompleteOnSelect(value, item)}
                                                renderMenu={(items, value, style) => {
                                                    return (
                                                        (autoCompleteDatas.length > 0)
                                                            ?
                                                            <div style={{ ...style, ...menuStyle }}>
                                                                {items}
                                                            </div>
                                                            :
                                                            <></>
                                                    );
                                                }}
                                                inputProps={{
                                                    className: 'form-control',
                                                    placeholder: translate(lang, 'customerName'),
                                                    name: 'filter_name',
                                                }}
                                                wrapperStyle={{ position: 'relative' }}
                                            />
                                            {
                                                (showRemoveAutoCompleteValue)
                                                    ?
                                                    <a className="auto-complete-close" style={{ "top": "10px", "right": "25px" }} onClick={e => { autoCompleteClose(e) }}><i className="fas fa-times"></i></a>
                                                    : <></>
                                            }
                                        </>
                                    }
                                    {(error.customer)
                                        ?
                                        <label className="text-danger">{error.customer}</label>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            <div className="form-group row mb-4 required">
                                <label htmlFor="input-phone" className="col-sm-2 col-form-label form-label">{translate(lang, 'phone')}</label>
                                <div className="col-sm-10">
                                    <div className="d-flex">
                                        {(pageType != 'view')
                                            ?
                                            <select className="form-control w-25" name="phoneCode" onChange={(e) => onInputChange(e)} value={postData.phoneCode}>
                                                {(phoneCodeData && phoneCodeData.length > 0)
                                                    ?
                                                    phoneCodeData.map((value) => (
                                                        <option key={value.id} value={value.phonecode}>+{value.phonecode}</option>
                                                    ))
                                                    :
                                                    <></>
                                                }
                                            </select>
                                            :
                                            <></>
                                        }
                                        <input type="text" id="input-phone" className="form-control" name="phone" value={postData.phone} placeholder={translate(lang, "phone")} onChange={(e) => onInputChange(e)} readOnly={pageType == 'view'} />
                                    </div>

                                    {(error.phone)
                                        ?
                                        <label className="text-danger">{error.phone}</label>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            <div className="form-group row mb-4">
                                <label htmlFor="input-email" className="col-sm-2 col-form-label">{translate(lang, 'email')}</label>
                                <div className="col-sm-10">
                                    <input type="text" id="input-email" className="form-control" name="email" value={postData.email} onChange={(e) => onInputChange(e)} placeholder={pageType === 'view' ? "" : translate(lang, 'optional')} readOnly={pageType == 'view'} />
                                </div>
                            </div>
                            <div className="form-group row mb-4">
                                <label htmlFor="input-customer-reference" className="col-sm-2 col-form-label">{translate(lang, 'customerReference')}</label>
                                <div className="col-sm-10">
                                    <input type="text" id="input-customer-reference" className="form-control" name="customerReference" placeholder={translate(lang, "optional")} value={postData.customerReference} onChange={(e) => onInputChange(e)} />
                                </div>
                            </div>
                            <div className="form-group row mb-4">
                                <label htmlFor="input-remark-1" className="col-sm-2 col-form-label">{translate(lang, 'remark_1')}</label>
                                <div className="col-sm-10">
                                    <input type="text" id="input-remark-1" className="form-control" name="remark_1" value={postData.remark_1} onChange={(e) => onInputChange(e)} placeholder={pageType === 'view' ? "" : translate(lang, 'optional')} readOnly={pageType == 'view'} />
                                </div>
                            </div>
                            <div className="form-group row mb-4">
                                <label htmlFor="input-remark-2" className="col-sm-2 col-form-label">{translate(lang, 'remark_2')}</label>
                                <div className="col-sm-10">
                                    <input type="text" id="input-remark-2" className="form-control" name="remark_2" value={postData.remark_2} onChange={(e) => onInputChange(e)} placeholder={pageType === 'view' ? "" : translate(lang, 'optional')} readOnly={pageType == 'view'} />
                                </div>
                            </div>
                            <div className="form-group row mb-4">
                                <label htmlFor="input-remark-3" className="col-sm-2 col-form-label">{translate(lang, 'remark_3')}</label>
                                <div className="col-sm-10">
                                    <input type="text" id="input-remark-3" className="form-control" name="remark_3" value={postData.remark_3} onChange={(e) => onInputChange(e)} placeholder={pageType === 'view' ? "" : translate(lang, 'optional')} readOnly={pageType == 'view'} />
                                </div>
                            </div>
                            <div className="form-group row mb-4 required">
                                <label htmlFor="input-balance" className="col-sm-2 col-form-label form-label">{translate(lang, 'balance')}</label>
                                <div className="col-sm-10">
                                    <div className="position-relative">
                                        <input type="text" id="input-balance" className="form-control input-balance" name="balance" placeholder={translate(lang, 'balance')} value={postData.balance} onChange={(e) => onInputChange(e)} readOnly={pageType == 'view'} />
                                        <span className="input-label-balance">{data.productUOMDesc}</span>
                                    </div>
                                    {(error.balance)
                                        ?
                                        <label className="text-danger">{error.balance}</label>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            <div className="form-group row py-2 required">
                                <label htmlFor="input-custody-date" className="col-sm-2 col-form-label form-label">{translate(lang, 'custodyDate')}</label>
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            {(pageType == 'view')
                                                ?
                                                <input type="text" className="form-control" value={postData.custodyDate} readOnly />
                                                :

                                                <DatePicker format={translate(lang, "date_format")} className="d-block date-picker-custom-form-control" value={postData.custodyDate} onChange={(e) => onDateChange(e, "custodyDate")} />
                                            }
                                            {(error.custodyDate)
                                                ?
                                                <label className="text-danger">{error.custodyDate}</label>
                                                :
                                                <></>
                                            }
                                        </div>
                                        <div className="col-sm-9">
                                            {(pageType == 'view')
                                                ?
                                                <>
                                                    <div className="row">
                                                        <label htmlFor="input-expiry-date" className="col-form-label text-end col-sm-5">{translate(lang, 'expiryDate')}</label>
                                                        <div className="col-sm-7">
                                                            <input id="input-expiry-date" className="form-control" value={postData.expiryDate} readOnly />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="row">
                                                        <label htmlFor="input-expiry-period" className="col-sm-3 col-form-label form-label">{translate(lang, 'expiryPeriod')}</label>

                                                        <div className="col-sm-9">
                                                            {(postData.expiryUnit && isChecked.lock_expiry_period)
                                                                ?
                                                                <>
                                                                <p className="text-white">{postData.expiryUnit} {postData.expirySelection}</p>
                                                                </>
                                                                :
                                                                <>
                                                                    <label className="radio-inline me-3">
                                                                        <input type="radio" name="expireType" value="1" defaultChecked onChange={(e) => onchangeExpireType(e)} /> {translate(lang, 'expiredAfter')}
                                                                    </label>
                                                                    <label className="radio-inline me-3">
                                                                        <input type="radio" name="expireType" value="2" onChange={(e) => onchangeExpireType(e)} /> {translate(lang, 'expiredOn')}
                                                                    </label>
                                                                    <label className="radio-inline me-3">
                                                                        <input type="radio" name="expireType" value="3" onChange={(e) => onchangeExpireType(e)} /> {translate(lang, 'neverExpired')}
                                                                    </label>

                                                                    <div>
                                                                        {(expiryType == 1)
                                                                            ?
                                                                            <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <input type="number" id="input-expiry-unit" className="form-control" name="expiryUnit" value={postData.expiryUnit} onChange={(e) => onInputChange(e)} />
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <select className="form-control" name="expirySelection" onChange={(e) => onInputChange(e)} value={postData.expirySelection}>
                                                                                        <option value="days">{translate(lang, 'days')}</option>
                                                                                        <option value="weeks">{translate(lang, 'weeks')}</option>
                                                                                        <option value="months">{translate(lang, 'months')}</option>
                                                                                        <option value="years">{translate(lang, 'years')}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            (expiryType == 2)
                                                                                ?
                                                                                <DatePicker format={translate(lang, "date_format")} className="d-block date-picker-custom-form-control" value={postData.expiryDate} onChange={(e) => onDateChange(e, "expiryDate")} />
                                                                                :
                                                                                <>
                                                                                    <span className="label label-primary">{translate(lang, "neverExpired")}</span>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </>
                                                            }

                                                            {(error.expiryPeriod)
                                                                ?
                                                                <label className="text-danger">{error.expiryPeriod}</label>
                                                                :
                                                                <></>
                                                            }

                                                            <div className="form-check custom-checkbox mt-3">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={isChecked.default_sms}
                                                                    onClick={(e) => checkboxClick(e)}
                                                                    id="checkbox2"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkbox2"> {translate(lang, "sendSmsUponSave")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={pageType === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/custody_list')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(CreateCustody);