import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getProduct, getCustomerAutoComplete, createCustody2, getMISC, getProductList, getPhoneCode } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
// import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";
import Autocomplete from 'react-autocomplete';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Table } from "reactstrap";

const CreateCustody2 = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const history = useHistory();

    const [error, setError] = useState({
        customer: "",
        phone: "",
        product: "",
        custodyData: [],
    });

    const [loadingData, setLoadingData] = useState(false);

    const today = new Date();
    // Format today's date to YYYY-MM-DD
    const defaultDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [postData, setPostData] = useState({
        name: "",
        phoneCode: "60",
        phone: "",
        email: "",
        custodyData: [],
    });

    const [expiryType, setExpiryType] = useState([{ expiryType: 1 }]);

    useEffect(() => {
        getMISCDetails();
    }, [])

    const onInputChange = (event) => {
        const { value, name } = event.target;
        let temp = postData;
        temp[name] = value;
        setPostData({ ...temp });
    };

    const onInputChange2 = (event, index, name) => {
        const { value } = event.target;
        let temp = postData;
        temp['custodyData'][index][name] = value;
        setPostData({ ...temp });
    };

    const submitForm = async () => {
        let check = true;
        let error2 = error;

        error2.customer = "";
        error2.phone = "";
        error2.product = "";

        if (!postData.name) {
            check = false;
            error2.customer = translate(lang, "errorRequired");
        }

        if (!postData.phoneCode) {
            check = false;
            error2.phone = translate(lang, "errorRequired");
        }

        if (!postData.phone) {
            check = false;
            error2.phone = translate(lang, "errorRequired");
        }

        if (postData.custodyData.length <= 0) {
            check = false;
            error2.product = translate(lang, "errorRequired");
        } else {
            postData.custodyData.forEach((element, index) => {
                error2.custodyData[index]['balance'] = "";
                error2.custodyData[index]['custodyDate'] = "";
                error2.custodyData[index]['expiryUnit'] = "";
                error2.custodyData[index]['expirySelection'] = "";

                if (element.balance <= 0) {
                    check = false;
                    error2.custodyData[index]['balance'] = translate(lang, "errorRequired");
                }
                if (!element.custodyDate) {
                    check = false;
                    error2.custodyData[index]['custodyDate'] = translate(lang, "errorRequired");
                }
                if (!element.expiryUnit) {
                    check = false;
                    error2.custodyData[index]['expiryUnit'] = translate(lang, "errorRequired");
                }
                if (!element.expirySelection) {
                    check = false;
                    error2.custodyData[index]['expirySelection'] = translate(lang, "errorRequired");
                }
            });
        }

        setError({ ...error2 });

        if (check) {
            const custody_data_arr = [];
            postData.custodyData.forEach((element, index) => {
                const tmp_custody_data = {};
                tmp_custody_data['product_id'] = parseInt(element.product_id);
                tmp_custody_data['balance'] = parseFloat(element.balance);
                tmp_custody_data['customer_reference'] = (element.customer_reference) ? element.customer_reference : "";
                tmp_custody_data['remark_1'] = (element.remark_1) ? element.remark_1 : "";
                tmp_custody_data['remark_2'] = (element.remark_2) ? element.remark_2 : "";
                tmp_custody_data['remark_3'] = (element.remark_3) ? element.remark_3 : "";
                tmp_custody_data['custody_date'] = element.custodyDate.format("YYYY-MM-DD");

                if (element.expireType == 1) {
                    const expiryDate = element.custodyDate.add(element.expiryUnit, element.expirySelection).format("YYYY-MM-DD");

                    tmp_custody_data['expiry_type'] = `expired after ${expiryDate}`;
                    tmp_custody_data['expiry_date'] = expiryDate;
                    tmp_custody_data['expiry_unit'] = element.expiryUnit;
                    tmp_custody_data['expiry_selection'] = (element.expirySelection) ? element.expirySelection : "days";
                }

                custody_data_arr.push(tmp_custody_data);
            });

            const tmp_post_data = {
                "merchant_id": merchant_id,
                "name": postData.name,
                "email": postData.email,
                "phoneCode": postData.phoneCode,
                "phonenumber": postData.phone,
                "custody_data": custody_data_arr
                // "sms_flag": (isChecked.default_sms) ? "1" : "0",
            }

            let post_data = tmp_post_data;

            await createCustody2(post_data).then((res) => {
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message);

                    history.push('/custody_list');

                    // if (res.data) {
                    //     if (res.data.premium_sms !== undefined && res.data.premium_sms === false) {
                    //         window.open(`sms:+${res.data.customer_phone}?body=${res.data.sms_body}`);
                    //         history.push('/custody_list');
                    //     } else {
                    //         history.push('/custody_list');
                    //     }
                    // } else {
                    // }
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })
        }
    }

    // AUTOCOMPLETE START
    const [autoCompleteDatas, setAutoCompleteData] = useState([]);
    const [showRemoveAutoCompleteValue, setShowRemoveAutoCompleteValue] = useState({
        customer: false,
        product: false,
    });
    const [autocompleteValue, setAutoCompleteValue] = useState({
        filter_name: "",
        filter_product_input: ""
    });

    const [autoCompleteProductData, setAutoCompleteProductData] = useState([]);

    const timerRef = useRef(null);

    async function autoComplete(type) {
        if (type == 'customer') {
            let query = { input: autocompleteValue.filter_name, limit: 5 }

            let res = await getCustomerAutoComplete(query);

            if (res?.status === 'success') {
                setAutoCompleteData(res.data)
            } else {
            }
        } else if (type == 'product') {
            let query = { name: autocompleteValue.filter_product_input, limit: 5 }

            let res = await getProductList(query);
            if (res?.status === 'success') {
                setAutoCompleteProductData(res.data.results)
            } else {
            }
        }
    }

    const autocompleteOnChange = (e, type) => {
        const tmp = autocompleteValue;
        tmp[e.target.name] = e.target.value;
        setAutoCompleteValue({ ...tmp });
        if (type == 'customer') {
            if (!e.target.value) {
                setAutoCompleteData([]);
            }
        } else if (type == 'product') {
            if (!e.target.value) {
                setAutoCompleteProductData([]);
            }
        }

        if (type == 'customer') {
            if (autocompleteValue.filter_name) {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    autoComplete(type);
                }, 500)
            }

            let tmp_data = postData;
            tmp_data.name = autocompleteValue.filter_name;
            setPostData({ ...tmp_data });
        } else if (type == 'product') {
            if (autocompleteValue.filter_product_input) {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    autoComplete(type);
                }, 500)
            }
        }
    }

    const autocompleteOnSelect = (e, value, type) => {
        let tmp_data = postData;
        let tmp_data_2 = autocompleteValue;

        if (type == 'customer') {
            tmp_data['phone'] = (value.phonenumber) ? value.phonenumber : "";
            tmp_data['phoneCode'] = (value.phonecode) ? value.phonecode : "";
            tmp_data['email'] = (value.email) ? value.email : "";
            // tmp_data['customerID'] = (value.id) ? value.id : "";
            tmp_data['name'] = (value.name) ? value.name : "";

            tmp_data_2.filter_name = value.name;

            setShowRemoveAutoCompleteValue({ customer: true });
            setAutoCompleteData([]);
        } else if (type == 'product') {
            const tmp_custody_data_obj = generateProductData(value);
            tmp_data['custodyData'].push(tmp_custody_data_obj);

            tmp_data_2.filter_product_input = "";
            setShowRemoveAutoCompleteValue({ product: false });
            setAutoCompleteProductData([]);
            setAutoCompleteInput(false);
        }

        setPostData({ ...tmp_data });
        setAutoCompleteValue({ ...tmp_data_2 });
    }

    const menuStyle = {
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        position: 'absolute',
        left: '0px',
        top: '40px',
        zIndex: '9',
        color: "#969ba0",
    };

    const menuStyle2 = {
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        zIndex: '9',
        color: "#969ba0",
    };

    const autoCompleteClose = (e, type) => {
        e.preventDefault();

        const tmp = autocompleteValue;

        if (type == 'customer') {
            tmp['filter_name'] = e.target.value;
        } else if (type == 'product') {
            tmp['filter_product_input'] = e.target.value;
        }

        setAutoCompleteValue({ ...tmp });

        let tmp_data = postData;

        if (type == 'customer') {
            tmp_data['phone'] = "";
            tmp_data['phoneCode'] = "60";
            tmp_data['email'] = "";
            // tmp_data['customerID'] = "";
            tmp_data['name'] = "";

            setAutoCompleteData([]);
            setShowRemoveAutoCompleteValue({ customer: false });

        } else if (type == 'product') {

            setAutoCompleteProductData([]);
            setShowRemoveAutoCompleteValue({ product: false });
        }

        setPostData({ ...tmp_data });

    }

    // AUTOCOMPLETE END

    const onDateChange = (e, index, name) => {
        if (e) {
            // let tmp_date = datePickerDate;
            // tmp_date[name] = e;
            // setDatePickerDate({ ...tmp_date });

            const convertedDate = dayjs(e.$d).format(translate(lang, 'date_format'));
            let temp = postData;
            temp['custodyData'][index][name] = e;
            setPostData({ ...temp });
        }
    }

    const onchangeExpireType = (e, index) => {
        const tmp_data = expiryType;
        tmp_data[index]['expiryType'] = e.target.value;

        setExpiryType([...tmp_data]);

        let tmp_post_data = postData;

        tmp_post_data['custodyData'][index]['expireType'] = e.target.value;

        setPostData({ ...tmp_post_data });
    }

    const [isChecked, setIsChecked] = useState({
        "custody_out_quantity": false,
        "approval_code": false,
        "premium_sms": false,
        "enable_qr": false,
        "default_sms": false,
        "lock_expiry_period": false
    });

    const getMISCDetails = async (slug) => {
        let res = await getMISC();

        if (res.status === 'success') {
            let data = res.data;

            setIsChecked({
                "custody_out_quantity": (data.custody_out_quantity) ? data.custody_out_quantity : false,
                "approval_code": (data.approval_code) ? data.approval_code : false,
                "premium_sms": (data.premium_sms) ? data.premium_sms : false,
                "enable_qr": (data.enable_qr) ? data.enable_qr : false,
                "default_sms": (data.default_sms) ? data.default_sms : false,
                "lock_expiry_period": (data.lock_expiry_period) ? data.lock_expiry_period : false
            })

        } else {
            // history.push('/settings/misc');
        }
    }

    const checkboxClick = (e) => {
        const tmp_data = isChecked;
        tmp_data.default_sms = !isChecked.default_sms;

        setIsChecked({ ...tmp_data });
    }

    const [tmpProductData, setTmpProductData] = useState([]);

    const deleteItem = (e, index) => {
        const tmp_data = tmpProductData;
        setTmpProductData([...tmp_data.slice(0, index), ...tmp_data.slice(index + 1)]);
        const tmp_data2 = postData;
        tmp_data2['custodyData'] = [...tmp_data2.custodyData.slice(0, index), ...tmp_data2.custodyData.slice(index + 1)];;
        setPostData({ ...tmp_data2 });
    }

    const generateProductData = (value) => {
        let tmp_data2 = tmpProductData;
        let existFlag = false;

        if (tmp_data2.length > 0) {
            tmp_data2.forEach((ele, ind) => {
                if (ele.id == value.id) {
                    existFlag = true;
                }
            });
        }

        const gg = {};

        if (!existFlag) {
            tmp_data2.push(value);

            // for custody data
            gg['product_id'] = value.id;
            gg['custodyDate'] = dayjs(defaultDate)
            gg['expireType'] = 1;
            gg['expiryUnit'] = (value.default_expiry_unit) ? value.default_expiry_unit : "";
            gg['expirySelection'] = (value.default_expiry_range) ? value.default_expiry_range : "days";
            gg['balance'] = "";
            gg['customer_reference'] = "";
            gg['remark_1'] = "";
            gg['remark_2'] = "";
            gg['remark_3'] = "";

            // tmp_data['custodyData'].push(gg);

            // for expiry type
            const tmp_data3 = expiryType;
            const gg2 = {};
            gg2['expiryType'] = 1;
            tmp_data3.push(gg2);
            setExpiryType([...tmp_data3]);
        }

        setTmpProductData([...tmp_data2]);

        // show more detail
        const tmp_data4 = showMoreDetail;
        const gg3 = {};
        gg3['show_more_detail'] = false;
        tmp_data4.push(gg3);
        setShowMoreDetail([...tmp_data4]);

        // set default error
        const default_err_arr = error;
        const gg4 = {};
        gg4['balance'] = "";
        gg4['custodyDate'] = "";
        gg4['expiryUnit'] = "";
        gg4['expirySelection'] = "";
        default_err_arr['custodyData'].push(gg4);

        setError({ ...default_err_arr });

        return gg;
    }

    const [showMoreDetail, setShowMoreDetail] = useState([]);

    const showMoreDetailAction = (e, index) => {
        const tmp_data4 = showMoreDetail;
        tmp_data4[index]['show_more_detail'] = !tmp_data4[index]['show_more_detail'];
        setShowMoreDetail([...tmp_data4]);
    }

    useEffect(() => {
        getPhoneCodeList();
    }, [])

    const [phoneCodeData, setPhoneCodeData] = useState([]);

    async function getPhoneCodeList() {
        let res = await getPhoneCode();

        if (res?.status === 'success') {
            setPhoneCodeData(res.data);
        }
    }

    const [autoCompleteInput, setAutoCompleteInput] = useState(false);

    const addItem = (value) => {
        setAutoCompleteInput(true);
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row ">
                <div className="col-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, "createCustody")}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">
                            {(loadingData)
                                ?
                                <>
                                    <div className="loader-div loader-lg-div"><i className="fas fa-spinner fa-spin"></i></div>
                                </>
                                :
                                <></>
                            }

                            <div className="form-group row mb-4 required">
                                <div className="col-sm-4">
                                    <label htmlFor="input-customer" className="form-label col-form-label">{translate(lang, 'customer')}</label>
                                    <div className="position-relative">
                                        <Autocomplete
                                            items={autoCompleteDatas}
                                            getItemValue={(item) =>
                                                item.name
                                            }
                                            renderItem={(item, isHighlighted) =>
                                                <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: "5px 7px" }} key={item.id}>
                                                    {item.name} {item.phonenumber ? '- ' + item.phonecode + item.phonenumber : <></>}
                                                </div>
                                            }
                                            value={autocompleteValue.filter_name} // Initially, no value is set
                                            onChange={(e) => autocompleteOnChange(e, "customer")}
                                            onSelect={(value, item) => autocompleteOnSelect(value, item, 'customer')}
                                            renderMenu={(items, value, style) => {
                                                return (
                                                    (autoCompleteDatas.length > 0)
                                                        ?
                                                        <div style={{ ...style, ...menuStyle }}>
                                                            {items}
                                                        </div>
                                                        :
                                                        <></>
                                                );
                                            }}
                                            inputProps={{
                                                className: 'form-control',
                                                placeholder: translate(lang, 'customerName'),
                                                name: 'filter_name',
                                            }}
                                            wrapperStyle={{ position: 'relative' }}
                                        />
                                        {
                                            (showRemoveAutoCompleteValue.customer)
                                                ?
                                                <a className="auto-complete-close" style={{ "top": "10px", "right": "25px" }} onClick={e => { autoCompleteClose(e, 'customer') }}><i className="fas fa-times"></i></a>
                                                : <></>
                                        }
                                        {(error.customer)
                                            ?
                                            <label className="text-danger">{error.customer}</label>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <label htmlFor="input-phone" className="form-label col-form-label">{translate(lang, 'phone')}</label>
                                    <div className="d-flex">
                                        <select className="form-control w-25" name="phoneCode" onChange={(e) => onInputChange(e)} value={postData.phoneCode}>
                                            {(phoneCodeData && phoneCodeData.length > 0)
                                                ?
                                                phoneCodeData.map((value) => (
                                                    <option key={value.id} value={value.phonecode}>+{value.phonecode}</option>
                                                ))
                                                :
                                                <></>
                                            }
                                        </select>
                                        <input type="text" id="input-phone" className="form-control" name="phone" value={postData.phone} onChange={(e) => onInputChange(e)} placeholder={translate(lang, "phone")} />
                                    </div>
                                    {(error.phone)
                                        ?
                                        <label className="text-danger">{error.phone}</label>
                                        :
                                        <></>
                                    }

                                </div>

                                <div className="col-sm-3">
                                    <label htmlFor="input-email" className="col-form-label">{translate(lang, 'email')}</label>
                                    {/* <div className="col-sm-10"> */}
                                    <input type="text" id="input-email" className="form-control" name="email" value={postData.email} onChange={(e) => onInputChange(e)} placeholder={translate(lang, 'optional')} />
                                    {/* </div> */}
                                </div>
                            </div>

                            <Table hover responsive className="create-custody-table">
                                <thead>
                                    <tr>
                                        <td>{translate(lang, "product")}</td>
                                        <td className="required-col">{translate(lang, "balance")}</td>
                                        <td className="required-col">{translate(lang, "custodyDate")}</td>
                                        <td className="required-col">{translate(lang, "expiryDate")}</td>
                                        <td>{translate(lang, "action")}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(tmpProductData && tmpProductData.length > 0)
                                        ?
                                        tmpProductData.map((value, index) => (
                                            <>
                                                <tr>
                                                    <td width="">
                                                        <p>
                                                            <img src={(value.default_prod_image) ? value.default_prod_image : logo512} alt={value.description} className="img-fluid" width={75} height={75} />
                                                        </p>
                                                        <span>
                                                            {value.description}
                                                        </span>
                                                    </td>
                                                    <td width="">
                                                        <div className="position-relative">
                                                            <input type="number" id={`input-balance-${index}`} className="form-control" name={`custodyData[${index}][balance]`} value={postData['custodyData'][index]['balance']} onChange={(e) => onInputChange2(e, index, 'balance')} />
                                                            <div className="text-end">
                                                                <span className="label label-xs label-primary">{value.uom_desc}</span>
                                                            </div>
                                                            {(error.custodyData[index]['balance'])
                                                                ?
                                                                <label className="text-danger">{error.custodyData[index]['balance']}</label>
                                                                :
                                                                <></>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td width="">
                                                        <DatePicker format={translate(lang, "date_format")} className="d-block date-picker-custom-form-control" name={`custodyData[${index}][custodyDate]`} value={postData.custodyData[index]['custodyDate']} onChange={(e) => onDateChange(e, index, "custodyDate")} />
                                                        {(error.custodyData[index]['custodyDate'])
                                                            ?
                                                            <label className="text-danger">{error.custodyData[index]['custodyDate']}</label>
                                                            :
                                                            <></>
                                                        }
                                                    </td>
                                                    <td width="">
                                                        {/* <label className="radio-inline me-3">
                                                            <input type="radio" name={`custodyData[${index}][expireType]`} value="1" defaultChecked onChange={(e) => onchangeExpireType(e, index)} /> {translate(lang, 'expiredAfter')}
                                                        </label>
                                                        <label className="radio-inline me-3">
                                                            <input type="radio" name={`custodyData[${index}][expireType]`} value="2" onChange={(e) => onchangeExpireType(e, index)} /> {translate(lang, 'expiredOn')}
                                                        </label>
                                                        <label className="radio-inline me-3">
                                                            <input type="radio" name={`custodyData[${index}][expireType]`} value="3" onChange={(e) => onchangeExpireType(e, index)} /> {translate(lang, 'neverExpired')}
                                                        </label> */}

                                                        <div>
                                                            {(postData['custodyData'][index]['expiryUnit'] && isChecked.lock_expiry_period)
                                                                ?
                                                                <>
                                                                {postData['custodyData'][index]['expiryUnit']} {postData['custodyData'][index]['expirySelection']}
                                                                </>
                                                                :
                                                                <>
                                                                    {(expiryType[index].expiryType == 1)
                                                                        ?
                                                                        <>
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <input type="number" id={`input-expiry-unit-${index}`} className="form-control" name={`custodyData[${index}][expiryUnit]`} value={postData['custodyData'][index]['expiryUnit']} onChange={(e) => onInputChange2(e, index, 'expiryUnit')} />
                                                                                    {(error.custodyData[index]['expiryUnit'])
                                                                                        ?
                                                                                        <label className="text-danger">{error.custodyData[index]['expiryUnit']}</label>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <select className="form-control" name={`custodyData[${index}][expirySelection]`} onChange={(e) => onInputChange2(e, index, 'expirySelection')} value={postData.custodyData[index].expirySelection}>
                                                                                        <option value="days">{translate(lang, 'days')}</option>
                                                                                        <option value="weeks">{translate(lang, 'weeks')}</option>
                                                                                        <option value="months">{translate(lang, 'months')}</option>
                                                                                        <option value="years">{translate(lang, 'years')}</option>
                                                                                    </select>
                                                                                    {(error.custodyData[index]['expirySelection'])
                                                                                        ?
                                                                                        <label className="text-danger">{error.custodyData[index]['expirySelection']}</label>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                        </>

                                                                        :
                                                                        <></>
                                                                        // (expiryType[index].expiryType == 2)
                                                                        //     ?
                                                                        //     <DatePicker format={translate(lang, "date_format")} className="d-block date-picker-custom-form-control" value={postData.expiryDate} onChange={(e) => onDateChange(e, "expiryDate")} />
                                                                        //     :
                                                                        //     <>
                                                                        //         <span className="label label-primary">{translate(lang, "neverExpired")}</span>
                                                                        //     </>
                                                                    }
                                                                </>
                                                            }

                                                        </div>
                                                        {(error.expiryPeriod)
                                                            ?
                                                            <label className="text-danger">{error.expiryPeriod}</label>
                                                            :
                                                            <></>
                                                        }
                                                        {/* <div className="form-check custom-checkbox mt-3 col-12">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={isChecked.default_sms}
                                                            onClick={(e) => checkboxClick(e)}
                                                            id="checkbox2"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="checkbox2"> {translate(lang, "sendSmsUponSave")}
                                                        </label>
                                                    </div> */}
                                                    </td>
                                                    <td>
                                                        <a className="btn btn-xs me-1" onClick={(e) => showMoreDetailAction(e, index)} title={translate(lang, "show")}><i className="fas fa-ellipsis-h"></i></a>
                                                        <a className="btn btn-xs me-1" onClick={(e) => deleteItem(e, index)} title={translate(lang, "remove")}><i className="fas fa-trash-alt"></i></a>
                                                    </td>
                                                </tr>
                                                {/* For More Details */}
                                                <tr hidden={(!showMoreDetail[index]['show_more_detail'])}>
                                                    <td colSpan={5}>
                                                        <div className="form-group row mb-4">
                                                            <div className="col-sm-3">
                                                                <label htmlFor="input-customer-reference" className="col-form-label">{translate(lang, 'customerReference')}</label>
                                                                <input type="text" id="input-customer-reference" className="form-control" name={`custodyData[${index}][customer_reference]`} value={postData['custodyData'][index]['customer_reference']} onChange={(e) => onInputChange2(e, index, 'customer_reference')} />
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <label htmlFor="input-remark-1" className="col-form-label">{translate(lang, 'remark_1')}</label>
                                                                <input type="text" id="input-remark-1" className="form-control" name={`custodyData[${index}][remark_1]`} value={postData['custodyData'][index]['remark_1']} onChange={(e) => onInputChange2(e, index, 'remark_1')} placeholder={translate(lang, 'optional')} />
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <label htmlFor="input-remark-2" className="col-form-label">{translate(lang, 'remark_2')}</label>
                                                                <input type="text" id="input-remark-2" className="form-control" name={`custodyData[${index}][remark_2]`} value={postData['custodyData'][index]['remark_2']} onChange={(e) => onInputChange2(e, index, 'remark_2')} placeholder={translate(lang, 'optional')} />
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <label htmlFor="input-remark-3" className="col-form-label">{translate(lang, 'remark_3')}</label>
                                                                <input type="text" id="input-remark-3" className="form-control" name={`custodyData[${index}][remark_3]`} value={postData['custodyData'][index]['remark_3']} onChange={(e) => onInputChange2(e, index, 'remark_3')} placeholder={translate(lang, 'optional')} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                        :
                                        <></>
                                    }
                                    {autoCompleteInput
                                        ?
                                        <tr>
                                            <td colSpan={5} style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                <div className="form-group required">
                                                    <div className="position-relative" >
                                                        <Autocomplete
                                                            items={autoCompleteProductData}
                                                            getItemValue={(item) =>
                                                                item.description
                                                            }
                                                            renderItem={(item, isHighlighted) =>
                                                                <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: "5px 7px" }} key={item.id}>
                                                                    {item.description} {item.category_1 ? '- ' + item.category_1 : <></>}
                                                                </div>
                                                            }
                                                            value={autocompleteValue.filter_product_input} // Initially, no value is set
                                                            onChange={(e) => autocompleteOnChange(e, "product")}
                                                            onSelect={(value, item) => autocompleteOnSelect(value, item, 'product')}
                                                            renderMenu={(items, value, style) => {
                                                                return (
                                                                    (autoCompleteProductData.length > 0)
                                                                        ?
                                                                        <div style={{ ...style, ...menuStyle2 }}>
                                                                            {items}
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                );
                                                            }}
                                                            inputProps={{
                                                                className: 'form-control',
                                                                placeholder: translate(lang, 'product'),
                                                                name: 'filter_product_input',
                                                            }}
                                                            wrapperStyle={{ position: 'relative' }}
                                                        />
                                                        {
                                                            (showRemoveAutoCompleteValue.product)
                                                                ?
                                                                <a className="auto-complete-close" style={{ "top": "10px", "right": "25px" }} onClick={e => { autoCompleteClose(e, 'product') }}><i className="fas fa-times"></i></a>
                                                                : <></>
                                                        }

                                                    </div>
                                                </div>
                                            </td>
                                            {/* <td colSpan={4}></td> */}
                                        </tr>
                                        :
                                        <></>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={4}></td>
                                        <td>
                                            <button className="btn btn-primary" onClick={(e) => addItem()}>{translate(lang, "addItem")}</button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                            {(error.product)
                                ?
                                <label className="text-danger">{error.product}</label>
                                :
                                <></>
                            }

                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, "submit")}</button>
                                <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/custody')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(CreateCustody2);