import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getCustodyList, getCustomerAutoComplete, getCustodyAutoComplete, extendCustody, getUsers, checkoutCustody, removeCustody, getMISC, sendSMS } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { Table } from "reactstrap";
import ReactPaginate from 'react-paginate';
import Autocomplete from 'react-autocomplete';
import Modal from 'react-modal';
import { Accordion } from "react-bootstrap";

Modal.setAppElement('#root');

const CustodyList = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const user_type = props.auth.auth.user_type;
    const timerRef = useRef(null);
    const history = useHistory();

    const [currentPage, setCurrentPage] = useState(1);
    const [loadingData, setLoadingData] = useState(false);
    const [filter, setFilter] = useState({
        filter_transaction_code: "",
        // filter_customer_id: "",
        filter_name: "",
        filter_status: "",
        filter_type: "active",
        filter_product_id: "",
        filter_sort_by: "",
        filter_order_by: "",
    });

    const [type, setType] = useState();

    const inputOnchange = (e) => {
        const tmp = filter;
        tmp[e.target.name] = e.target.value;
        setFilter({ ...tmp });
    };

    const sortOnchange = (event) => {
        const { name } = event.target;

        const tmp = filter;
        if (tmp.filter_order_by && tmp.filter_sort_by == name && tmp.filter_order_by == 'asc') {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = "desc";
        } else {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = 'asc'
        }

        setFilter({ ...tmp });
        setCurrentPage(1);
        getList();
    };

    const submitFilter = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        getList();
    }

    const [data, setData] = useState([]);

    useEffect(() => {
        getList();
    }, [currentPage])

    async function getList() {
        setLoadingData(true);
        // console.log(`GET LIST FILTER ${filter.filter_type}`);
        let query = {
            id: merchant_id,
            code: filter.filter_transaction_code,
            customer_id: filter.filter_customer_id,
            product_id: filter.filter_product_id,
            status: filter.filter_status,
            type: filter.filter_type,
            name: filter.filter_name,
            // prod_desc: filter.filter_product_name,
            page: currentPage,
            sort_by: filter.filter_sort_by,
            order_by: filter.filter_order_by,
        };

        let res = await getCustodyList(query);

        setType(filter.filter_type);

        if (res?.status === 'success') {
            setData(res.data)
            // setFileData([...res.data])
        } else {
        }
        setLoadingData(false);
    }

    const [autocompleteValue, setAutoCompleteValue] = useState({
        filter_name: "",
        filter_product_input: ""
    });

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };

    const pageCount = Math.ceil(data.total_result / 20);

    // AUTOCOMPLETE START
    const [autoCompleteDatas, setAutoCompleteData] = useState([]);
    const [custodyAutoCompleteData, setCustodyAutoCompleteData] = useState([]);
    const [showRemoveAutoCompleteValue, setShowRemoveAutoCompleteValue] = useState(false);
    const [showRemoveAutoCompleteValue1, setShowRemoveAutoCompleteValue1] = useState(false);

    async function autoComplete() {
        let query = { input: autocompleteValue.filter_name, limit: 5 }

        let res = await getCustomerAutoComplete(query);

        if (res?.status === 'success') {
            setAutoCompleteData(res.data)
        } else {
        }
    }

    async function custodyAutocomplete() {
        let query = { input: autocompleteValue.filter_product_input, merchant_id: props.merchant_id, limit: 5 }

        let res = await getCustodyAutoComplete(query);

        if (res?.status === 'success') {
            setCustodyAutoCompleteData(res.data)
        } else {
        }
    }

    const autocompleteOnChange = (e, type) => {
        const tmp = autocompleteValue;
        tmp[e.target.name] = e.target.value;
        setAutoCompleteValue({ ...tmp });

        if (!e.target.value) {
            // tmp[e.target.name] = '';
            // setAutoCompleteValue({ ...tmp });
            setAutoCompleteData([]);
            setCustodyAutoCompleteData([]);
            // const tmp2 = filter;
            // tmp2['filter_customer_id'] = "";
            // setFilter({ ...tmp });
        }

        if (type == 'customer') {
            if (autocompleteValue.filter_name) {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    autoComplete();
                }, 500)
            }
        } else if (type == 'custody') {
            if (autocompleteValue.filter_product_input) {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    custodyAutocomplete();
                }, 500)
            }
        }
    }

    const autocompleteOnSelect = (e, value) => {
        const tmp = filter;
        tmp['filter_customer_id'] = value.id;
        setFilter({ ...tmp });

        setAutoCompleteValue({ filter_name: value.name });
        setShowRemoveAutoCompleteValue(true);
        setAutoCompleteData([]);
    }

    const autocompleteOnSelect1 = (e, value) => {
        const tmp = filter;
        tmp['filter_product_id'] = value.id;
        setFilter({ ...tmp });

        setAutoCompleteValue({ filter_product_input: value.description });
        setShowRemoveAutoCompleteValue1(true);
        setCustodyAutoCompleteData([]);
    }

    const menuStyle = {
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        position: 'absolute',
        left: '0px',
        top: '40px',
    };

    const autoCompleteClose = (e) => {
        e.preventDefault();

        const tmp = autocompleteValue;
        tmp['filter_name'] = e.target.value;
        setAutoCompleteValue({ ...tmp });

        const tmp2 = filter;
        tmp2['filter_customer_id'] = "";
        setFilter({ ...tmp2 });

        setAutoCompleteData([]);
        setShowRemoveAutoCompleteValue(false);
    }

    const autoCompleteClose1 = (e) => {
        e.preventDefault();

        const tmp = autocompleteValue;
        tmp['filter_product_input'] = e.target.value;
        setAutoCompleteValue({ ...tmp });

        const tmp2 = filter;
        tmp2['filter_product_id'] = "";
        setFilter({ ...tmp2 });

        setCustodyAutoCompleteData([]);
        setShowRemoveAutoCompleteValue1(false);
    }

    // AUTOCOMPLETE END

    const [postData, setPostData] = useState({
        custody_code: "",
        item: "",
        expiry_date: "",
        custody_id: "",
        extend_duration: "",
        remark: "",
        bartender: "",
        quantity: "",
        expiry_unit: "",
        expiry_selection: "days",
        uom_desc: "",
        new_quantity: "",
        customer_name: "",
        customer_phone: "",
    });

    const [customerData, setCustomerData] = useState({});

    // MODAL START
    const [isModal1Open, setIsModal1Open] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [isModal3Open, setIsModal3Open] = useState(false);
    const [isModal4Open, setIsModal4Open] = useState(false);
    const [isModal5Open, setIsModal5Open] = useState(false);

    const openModal1 = (custody) => {
        if (custody.expiry_type == 'never expired') {
            promptMessage(lang, 'warning', translate(lang, 'errorExtendCustody'))
        } else {
            setPostData({
                custody_code: custody.code,
                item: custody.prod_desc,
                expiry_date: moment.utc(custody.expiry_date).utcOffset(8).format(translate(lang, 'date_format')),
                expiry_selection: "days",
            })
            setIsModal1Open(true);
        }
    };

    const closeModal1 = () => {
        setPostData({
            custody_code: "",
            item: "",
            expiry_date: "",
            custody_id: "",
            extend_duration: "",
            remark: "",
            bartender: "",
            quantity: "",
            expiry_unit: "",
            expiry_selection: "days",
            uom_desc: "",
            new_quantity: "",
            customer_name: "",
            customer_phone: "",
            approval_code: "",
        });

        setIsModal1Open(false);
    };

    const openModal2 = (custody) => {
        setPostData({
            custody_code: custody.code,
            item: custody.prod_desc,
            bartender: custody.bartender,
            uom_desc: custody.prod_uom_desc,
            quantity: custody.net_balance,
            new_quantity: custody.net_balance,
        })
        setIsModal2Open(true);
    };

    const closeModal2 = () => {
        setPostData({
            custody_code: "",
            item: "",
            expiry_date: "",
            custody_id: "",
            extend_duration: "",
            remark: "",
            bartender: "",
            quantity: "",
            expiry_unit: "",
            expiry_selection: "days",
            uom_desc: "",
            new_quantity: "",
            customer_name: "",
            customer_phone: "",
            approval_code: "",
        });
        setIsModal2Open(false);
    };

    const openModal3 = (custody) => {
        setPostData({
            custody_code: custody.code,
            item: custody.prod_desc,
            customer_name: custody.customer_name,
            customer_phone: custody.customer_phone,
            uom_desc: custody.prod_uom_desc,
            quantity: custody.net_balance,
            new_quantity: custody.net_balance,
        })
        setIsModal3Open(true);
    };

    const closeModal3 = () => {
        setPostData({
            custody_code: "",
            item: "",
            expiry_date: "",
            custody_id: "",
            extend_duration: "",
            remark: "",
            bartender: "",
            quantity: "",
            expiry_unit: "",
            expiry_selection: "days",
            uom_desc: "",
            new_quantity: "",
            customer_name: "",
            customer_phone: "",
            approval_code: "",
        });
        setIsModal3Open(false);
    };

    const openModal4 = (custody) => {
        setCustomerData(custody);
        setIsModal4Open(true);
    };

    const closeModal4 = () => {
        setCustomerData({});
        setIsModal4Open(false);
    };

    const openModal5 = (custody) => {
        setCustomerData(custody);
        setIsModal5Open(true);
    };

    const closeModal5 = () => {
        setCustomerData({});
        setIsModal5Open(false);
    };

    const customStyles = {
        content: {
            transform: 'translate(0, 0%)',
        },
    };

    const customStyles1 = {
        content: {
            transform: 'translate(0, 100%)',
        },
    };

    // MODAL END

    const [error, setError] = useState({
        expiry_unit: "",
        new_quantity: "",
        bartender: "",
        approval_code: "",
    });

    const modalInputOnchange = (e) => {
        const tmp = postData;
        tmp[e.target.name] = e.target.value;
        setPostData({ ...tmp });
    };

    const submitForm = async () => {
        let check = true;
        let error = {
            expiry_unit: "",
        }

        if (!postData.expiry_unit) {
            check = false;
            error.expiry_unit = translate(lang, "errorRequired");
        }

        if (!postData.custody_code) {
            check = false;
            promptMessage(lang, 'error', translate(lang, 'errorSomethingWentWrong'))
        }

        setError({ ...error });

        if (check) {
            const new_expiry_date = moment(postData.expiry_date).add(postData.expiry_unit, postData.expiry_selection).format(translate(lang, "date_format"));

            const form_data = {
                custody_code: postData.custody_code,
                expiry_type: `expired on ${new_expiry_date}`,
                expiry_date: new_expiry_date,
                expiry_unit: postData.expiry_unit,
                expiry_selection: postData.expiry_selection,
                remark: "",
                sms_flag: (modalCheckBox.extend_custody) ? "1" : "0"
            };

            await extendCustody(form_data).then((res) => {
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    closeModal1();

                    if (res.data) {
                        if (res.data.premium_sms !== undefined && res.data.premium_sms === false) {
                            window.open(`sms:+${res.data.customer_phone}?body=${res.data.sms_body}`);
                        }
                    }

                    getList();

                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })

        }
    }

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        getUserList();
        getMISCDetails();
    }, [])

    async function getUserList() {
        let query = {
            user_type: "bartender",
            limit: "999",
            sort_by: "display_name",
            order_by: "asc",
        };

        let res = await getUsers(query);

        if (res?.status === 'success') {
            setUserData(res.data);
        }
    }

    const submitForm2 = async () => {
        let check = true;
        let error = {
            expiry_unit: "",
            new_quantity: "",
            bartender: "",
            approval_code: "",
        }

        if (!postData.new_quantity) {
            check = false;
            error.new_quantity = translate(lang, "errorRequired");
        } else {
            if (postData.new_quantity > postData.quantity) {
                check = false;
                error.new_quantity = translate(lang, "errorQuantityExceed") + ' ' + postData.quantity;
            }
        }

        if (isChecked.approval_code && user_type == 'bartender') {
            if (!postData.approval_code) {
                check = false;
                error.approval_code = translate(lang, "errorRequired");
            }
        }

        // if (!postData.bartender) {
        //     check = false;
        //     error.bartender = translate(lang, "errorRequired");
        // }

        if (!postData.custody_code) {
            check = false;
            promptMessage(lang, 'error', translate(lang, 'errorSomethingWentWrong'))
        }

        setError({ ...error });

        if (check) {

            const form_data = {
                custody_code: postData.custody_code,
                bartender: postData.bartender,
                quantity: postData.new_quantity,
                remark: (postData.remark) ? postData.remark : "",
                type: "checkout",
                approval_code: postData.approval_code,
                sms_flag: (modalCheckBox.checkout_custody) ? "1" : "0"
            };

            await checkoutCustody(form_data).then((res) => {
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    closeModal2();

                    if (res.data) {
                        if (res.data.premium_sms !== undefined && res.data.premium_sms === false) {
                            window.open(`sms:+${res.data.customer_phone}?body=${res.data.sms_body}`);
                        }
                    }

                    getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })

        }
    }

    const submitForm3 = async () => {
        let check = true;

        if (!postData.custody_code) {
            check = false;
            promptMessage(lang, 'error', translate(lang, 'errorSomethingWentWrong'))
        }

        if (check) {

            const form_data = {
                custody_code: postData.custody_code,
                quantity: postData.new_quantity,
                remark: (postData.remark) ? postData.remark : "",
                type: "transfer",
                sms_flag: "0"
            };

            await checkoutCustody(form_data).then((res) => {
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    closeModal3();
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })

        }
    }

    const [disableButton, setDisableButton] = useState(false);

    const submitForm4 = async () => {
        setDisableButton(true);

        let check = true;

        if (!customerData.id) {
            check = false;
            promptMessage(lang, 'error', "Send Failed..");
        }

        if (check) {
            const form_data = {
                transaction_id: customerData.id,
            };

            await sendSMS(form_data).then((res) => {
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    closeModal5();
                    // getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })
        }

        setDisableButton(false);
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeCustody(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                    getList();
                }
            }
        })
    }

    function TextWithLineBreaks(text) {
        // Split the text into an array of lines using '\n' as the separator
        if (text) {
            const lines = text.split('\n');

            return (
                <div>
                    {lines.map((line, index) => (
                        // <React.Fragment key={index}>
                        <>
                            {line}
                            {index < lines.length - 1 && <br />} {/* Add <br> except for the last line */}
                        </>
                        // </React.Fragment>
                    ))}
                </div>
            );
        }
    }

    const [isChecked, setIsChecked] = useState({
        "custody_out_quantity": false,
        "approval_code": false,
        "premium_sms": false,
        "enable_qr": false,
        "default_sms": false
    });

    const getMISCDetails = async (slug) => {
        let res = await getMISC();

        if (res.status === 'success') {
            let data = res.data;

            setIsChecked({
                "custody_out_quantity": (data.custody_out_quantity) ? data.custody_out_quantity : false,
                "approval_code": (data.approval_code) ? data.approval_code : false,
                "premium_sms": (data.premium_sms) ? data.premium_sms : false,
                "enable_qr": (data.enable_qr) ? data.enable_qr : false,
                "default_sms": (data.default_sms) ? data.default_sms : false
            })

            setModalCheckBox({
                extend_custody: (data.default_sms) ? data.default_sms : false,
                checkout_custody: (data.default_sms) ? data.default_sms : false,
            });

        } else {
            // history.push('/settings/misc');
        }
    }

    const [activeAccordionWithIcon, setActiveAccordionWithIcon] = useState(0);

    const [modalCheckBox, setModalCheckBox] = useState({
        extend_custody: false,
        checkout_custody: false,
    });

    const checkboxClick = (e, name) => {
        const tmp_data = modalCheckBox;
        tmp_data[name] = !modalCheckBox[name];
        setModalCheckBox({ ...tmp_data });

        console.log(modalCheckBox);
    }

    return (
        <>
            <div className="card">
                <div className="card-body">

                    <Accordion
                        className="accordion accordion-with-icon"
                        defaultActiveKey="0"
                    >
                        <div className="accordion-item" key={1}>
                            <Accordion.Toggle
                                as={`div`}
                                eventKey={`1`}
                                className={`accordion-header ${activeAccordionWithIcon === 1 ? "" : "collapsed"
                                    }`}
                                onClick={() =>
                                    setActiveAccordionWithIcon(
                                        activeAccordionWithIcon === 1 ? -1 : 1
                                    )
                                }
                            >
                                {/* <span className="accordion-header-icon"></span> */}
                                <span className="accordion-header-text ps-0"><i className="fas fa-filter"></i> {translate(lang, "btnFilter")}</span>
                                <span className="accordion-header-indicator indicator-bordered"></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`1`}>
                                <div className="accordion-body-text">
                                    <div className="header-filter">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="filter-code">{translate(lang, 'transactionCode')}</label>
                                                    <input id="filter-code" type="text" name="filter_transaction_code" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'transactionCode')}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3 position-relative">
                                                    <label htmlFor="filter-name">{translate(lang, 'customerNameorPhone')}</label>
                                                    {/* <Autocomplete
                                        items={autoCompleteDatas}
                                        getItemValue={(item) =>
                                            item.name
                                        }
                                        renderItem={(item, isHighlighted) =>
                                            <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: "5px 7px" }} key={item.id}>
                                                {item.name} {item.phone ? '- ' + item.phone : <></>}
                                            </div>
                                        }
                                        value={autocompleteValue.filter_name} // Initially, no value is set
                                        onChange={(e) => autocompleteOnChange(e, "customer")}
                                        onSelect={(value, item) => autocompleteOnSelect(value, item)}
                                        renderMenu={(items, value, style) => {
                                            return (
                                                <div style={{ ...style, ...menuStyle }}>
                                                    {items}
                                                </div>
                                            );
                                        }}
                                        inputProps={{
                                            className: 'form-control',
                                            placeholder: translate(lang, 'customerName'),
                                            name: 'filter_name',
                                        }}
                                        wrapperStyle={{ position: 'relative' }}
                                    />
                                    {
                                        (showRemoveAutoCompleteValue)
                                            ?
                                            <a className="auto-complete-close" onClick={e => { autoCompleteClose(e) }}><i className="fas fa-times"></i></a>
                                            : <></>
                                    } */}

                                                    <input id="filter-name" type="text" name="filter_name" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'customerNameorPhone')}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3 position-relative">
                                                    <label htmlFor="filter-product">{translate(lang, 'productName')}</label>
                                                    <Autocomplete
                                                        items={custodyAutoCompleteData}
                                                        getItemValue={(item) =>
                                                            item.description
                                                        }
                                                        renderItem={(item, isHighlighted) =>
                                                            <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: "5px 7px" }} key={item.id}>
                                                                {item.description} {item.code ? '- ' + item.code : <></>}
                                                            </div>
                                                        }
                                                        value={autocompleteValue.filter_product_input} // Initially, no value is set
                                                        onChange={(e) => autocompleteOnChange(e, "custody")}
                                                        onSelect={(value, item) => autocompleteOnSelect1(value, item)}
                                                        renderMenu={(items, value, style) => {
                                                            return (
                                                                (custodyAutoCompleteData.length > 0)
                                                                    ?
                                                                    <div style={{ ...style, ...menuStyle }}>
                                                                        {items}
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            );
                                                        }}
                                                        inputProps={{
                                                            className: 'form-control',
                                                            placeholder: translate(lang, 'productName'),
                                                            name: 'filter_product_input',
                                                        }}
                                                        wrapperStyle={{ position: 'relative' }}
                                                    />
                                                    {
                                                        (showRemoveAutoCompleteValue1)
                                                            ?
                                                            <a className="auto-complete-close" onClick={e => { autoCompleteClose1(e) }}><i className="fas fa-times"></i></a>
                                                            : <></>
                                                    }
                                                    {/* <input id="filter-product" type="text" name="filter_product_name" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'productName')}></input> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="filter-status">{translate(lang, 'status')}</label>
                                                    <select id="filter-status" name="filter_type" className="form-control" onChange={(e) => inputOnchange(e)}>
                                                        <option value="active">{translate(lang, 'active')}</option>
                                                        <option value="checkout">{translate(lang, 'checkout')}</option>
                                                        <option value="expired">{translate(lang, 'expired')}</option>
                                                        <option value="transfer">{translate(lang, 'transfer')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-filter-button text-end">
                                            <button className="btn btn-primary btn-md mt-3" onClick={(e) => submitFilter(e)}>{translate(lang, 'btnFilter')}</button>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        <h3>{translate(lang, "custodyList")}</h3>
                        <div className="header-button my-4">
                            {/* <div className="float-start"> */}
                            <a href="/custody" className="btn btn-primary btn-sm me-3">{translate(lang, 'createCustody')}</a>
                            <a href="/create_custody" className="btn btn-primary btn-sm">{translate(lang, 'createCustody2')}</a>
                            {/* </div> */}
                        </div>
                    </div>
                    {(loadingData) ?
                        <>
                            <div className="loader-div"><i className="fas fa-spinner fa-spin"></i></div>
                        </>
                        :
                        <>
                            <div className="table-responsive">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <td>
                                                {translate(lang, 'transactionDetail')}
                                            </td>
                                            {(type == 'active' || type == 'expired')
                                                ?
                                                <td>
                                                    {translate(lang, 'expireDate')}
                                                </td>
                                                :
                                                <></>
                                            }
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'created_date') ? filter.filter_order_by : ""} filter_sort`} name="created_date" onClick={(e) => sortOnchange(e)}>{translate(lang, 'transactionDate')}</a>
                                            </td>
                                            {(type == 'checkout')
                                                ?
                                                <td>
                                                    {translate(lang, "checkOutBy")}
                                                </td>
                                                : <></>
                                            }
                                            <td>
                                                {translate(lang, 'action')}
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(data && data.result && data.result.length > 0)
                                            ?
                                            data.result.map((value) => (
                                                <tr key={value.id}>
                                                    <td scope="row" class="text-nowrap">
                                                        <a href={`/custody/view_${type}/${value.id}`} className="mb-0 text-secondary"><i className="fa fa-tag" aria-hidden="true"></i> {value.code}</a>
                                                        <p className="ms-2 mb-0 cursor-pointer text-green" onClick={(e) => { openModal4(value) }}><i className="fa fa-user" aria-hidden="true"></i> {value.customer_name} - {value.customer_phone}</p>
                                                        <p className="ms-2 mb-0"><i className="fa fa-beer" aria-hidden="true"></i> {value.prod_desc} ({value.prod_code})</p>
                                                        <p className="ms-2 mb-0"><i className="fa fa-tint" aria-hidden="true"></i> {value.net_balance} {value.prod_uom_desc}</p>
                                                        {(type == 'transfer')
                                                            ?
                                                            <p className="ms-2 mb-0">{translate(lang, "transferBy")} <strong>{value.created_by_info.display_name}</strong></p>
                                                            :
                                                            <></>
                                                        }
                                                    </td>
                                                    {(type == 'active' || type == 'expired')
                                                        ?
                                                        <td scope="row" class="text-nowrap">
                                                            {
                                                                (value.expiry_type == 'never expired')
                                                                    ?
                                                                    translate(lang, 'neverExpired')
                                                                    :
                                                                    <>
                                                                        {(type != 'expired')
                                                                            ?
                                                                            <>
                                                                                <span><i className="fa fa-calendar" aria-hidden="true"></i> {moment.utc(value.custody_date).utcOffset(8).format(translate(lang, 'date_format'))}</span>
                                                                                <br />
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                        <span><i className="fa fa-calendar" aria-hidden="true"></i> {moment.utc(value.expiry_date).utcOffset(8).format(translate(lang, 'date_format'))}</span>
                                                                        {(type != 'expired')
                                                                            ?
                                                                            <>
                                                                                < br />
                                                                                <span>{value.day_left}</span>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </>
                                                            }<br />
                                                        </td>
                                                        :
                                                        <></>
                                                    }
                                                    <td scope="row" class="text-nowrap">{moment.utc(value.created_date).utcOffset(8).format(translate(lang, 'date_time_format'))}</td>
                                                    {(type == 'checkout')
                                                        ?
                                                        <td>
                                                            {value.created_by_info.display_name}
                                                        </td>
                                                        : <></>
                                                    }
                                                    <td scope="row" >
                                                        {/* <a className="btn btn-xs btn-primary me-1" href="#"><i className="fas fa-sms"></i></a> */}

                                                        {(type == 'active' || type == 'expired')
                                                            ?
                                                            <>
                                                                {(type == 'active')
                                                                    ?
                                                                    <>
                                                                        <button className="btn btn-primary me-2 mb-2 py-3" onClick={(e) => { openModal5(value) }} title={translate(lang, 'remind')}><i className="fas fa-paper-plane"></i></button>
                                                                        <button className="btn btn-primary me-2 mb-2 py-3" onClick={(e) => { openModal1(value) }} title={translate(lang, 'extend')}><i className="fas fa-plus-circle"></i></button>
                                                                    </>
                                                                    :
                                                                    <button className="btn btn-primary me-2 mb-2 py-3" onClick={(e) => { openModal3(value) }} title={translate(lang, 'transfer')}><i className="fas fa-exchange-alt"></i></button>
                                                                }
                                                                <button className="btn btn-primary me-2 mb-2 py-3" onClick={(e) => { openModal2(value) }} title={translate(lang, 'checkout')}><i className="fas fa-sign-out-alt"></i></button>
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                        <button className="btn me-2 mb-2" title={translate(lang, 'remove')} onClick={() => removeItem(value.id)}><i className="fas fa-trash-alt"></i></button>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={6} className="text-center">{translate(lang, 'noResult')}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {(data && data.result && data.result.length > 0)
                                ?
                                <ReactPaginate
                                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                    previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                    nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                    disabledClassName={"pagination__link--disabled text-muted"}
                                    activeClassName={"pagination__link--active text-white"}
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    forcePage={currentPage - 1}
                                />
                                : <></>}
                        </>
                    }
                </div>
            </div>

            {/* MODAL */}
            {/* Extend Custody Modal */}
            <Modal
                isOpen={isModal1Open}
                onRequestClose={closeModal1}
                contentLabel="Modal 1"
                style={customStyles}
                className={"modal-dialog"}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="m-0"><i className="fas fa-plus-circle"></i> {translate(lang, "extendCustody")}</h4>
                        <button className="close" style={{ border: "0", backgroundColor: "transparent", fontSize: "16px", padding: "12px" }} onClick={closeModal1}><i className="fas fa-times"></i></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group mb-3">
                            <label>{translate(lang, 'custodyCode')}</label>
                            <p className="mb-0"><strong>{postData.custody_code}</strong></p>
                        </div>
                        <div className="form-group mb-3">
                            <label>{translate(lang, 'item')}</label>
                            <p className="mb-0"><strong>{postData.item}</strong></p>
                        </div>
                        <div className="form-group mb-3">
                            <label>{translate(lang, 'currentExpiryDate')}</label>
                            <p className="mb-0"><strong>{postData.expiry_date}</strong></p>
                        </div>
                        <div className="form-group mb-3 required">
                            <label htmlFor="input-expiry-unit" className="form-label">{translate(lang, 'extendDuration')}</label>
                            <div className="d-flex">
                                <div className="w-50">
                                    <input id="input-expiry-unit" type="number" className="form-control" name="expiry_unit" onChange={(e) => modalInputOnchange(e)} />
                                    {(error.expiry_unit)
                                        ?
                                        <label className="text-danger">{error.expiry_unit}</label>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="w-50">
                                    <select className="form-control" name="expiry_selection" onChange={(e) => modalInputOnchange(e)}>
                                        <option value="days">{translate(lang, 'days')}</option>
                                        <option value="weeks">{translate(lang, 'weeks')}</option>
                                        <option value="months">{translate(lang, 'months')}</option>
                                        <option value="years">{translate(lang, 'years')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form-check custom-checkbox mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={modalCheckBox.extend_custody}
                                onClick={(e) => checkboxClick(e, 'extend_custody')}
                                id="checkbox2"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="checkbox2"> {translate(lang, "sendSmsUponSave")}
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="input-remark">{translate(lang, 'remark')}</label>
                            <input id="input-remark" type="text" className="form-control" name="remark" onChange={(e) => modalInputOnchange(e)} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-success" onClick={submitForm}>{translate(lang, 'submit')}</button>
                        <button className="btn btn-danger" onClick={closeModal1}>{translate(lang, 'cancel')}</button>
                    </div>
                </div>
            </Modal>

            {/* Checkout Custody Modal */}
            <Modal
                isOpen={isModal2Open}
                onRequestClose={closeModal2}
                contentLabel="Modal 2"
                style={customStyles}
                className={"modal-dialog"}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="m-0"><i className="fas fa-sign-out-alt"></i> {translate(lang, "checkout")}</h4>
                        <button className="close" style={{ border: "0", backgroundColor: "transparent", fontSize: "16px", padding: "12px" }} onClick={closeModal2}><i className="fas fa-times"></i></button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: "500px", overflow: "auto" }}>
                        <div className="form-group mb-3">
                            <label>{translate(lang, 'custodyCode')}</label>
                            <p className="mb-0"><strong>{postData.custody_code}</strong></p>
                        </div>
                        <div className="form-group mb-3">
                            <label>{translate(lang, 'item')}</label>
                            <p className="mb-0"><strong>{postData.item}</strong></p>
                        </div>
                        <div className="form-group mb-3">
                            <label>{translate(lang, 'bartender')}</label>
                            <select className="form-control" name="bartender" value={postData.bartender} onChange={(e) => modalInputOnchange(e)}>
                                <option value="">{translate(lang, "pleaseSelect")}</option>
                                {(userData && userData.results && userData.results.length > 0)
                                    ?
                                    userData.results.map((item) => (
                                        <option key={item.id} value={item.display_name}>{item.display_name}</option>
                                    ))
                                    : <></>
                                }
                            </select>
                            {(error.bartender)
                                ?
                                <label className="text-danger">{error.bartender}</label>
                                :
                                <></>
                            }
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="input-remark">{translate(lang, 'remark')}</label>
                            <input id="input-remark" type="text" className="form-control" name="remark" onChange={(e) => modalInputOnchange(e)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="input-balance">{translate(lang, 'quantity')}</label>
                            <div className="position-relative">
                                <input type="number" id="input-balance" className="form-control input-balance" name="new_quantity" onChange={(e) => modalInputOnchange(e)} value={postData.new_quantity} />
                                <span className="input-label-balance" style={{ right: "0" }}>{postData.uom_desc}</span>
                            </div>
                            {(error.new_quantity)
                                ?
                                <label className="text-danger">{error.new_quantity}</label>
                                :
                                <></>
                            }
                        </div>
                        <div className="form-check custom-checkbox mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={modalCheckBox.checkout_custody}
                                onClick={(e) => checkboxClick(e, 'checkout_custody')}
                                id="checkbox2"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="checkbox2"> {translate(lang, "sendSmsUponSave")}
                            </label>
                        </div>
                        <div className="form-group mb-3 required">
                            {/* {(isChecked.approval_code) */}
                            {(isChecked.approval_code && user_type == 'bartender')
                                ?
                                <>
                                    <label htmlFor="input-approval-code" className="form-label">{translate(lang, 'approvalCode')}</label>
                                    <input id="input-approval-code" type="password" className="form-control" name="approval_code" onChange={(e) => modalInputOnchange(e)} />
                                </>
                                :
                                <>
                                    <label htmlFor="input-approval-code">{translate(lang, 'approvalCodeNotRequired')}</label>
                                </>
                            }
                            {(error.approval_code)
                                ?
                                <label className="text-danger">{error.approval_code}</label>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-success" onClick={submitForm2}>{translate(lang, 'submit')}</button>
                        <button className="btn btn-danger" onClick={closeModal2}>{translate(lang, 'cancel')}</button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isModal3Open}
                onRequestClose={closeModal3}
                contentLabel="Modal 3"
                style={customStyles}
                className={"modal-dialog"}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="m-0"><i className="fas fa-exchange-alt"></i> {translate(lang, "transferToInventory")}</h4>
                        <button className="close" style={{ border: "0", backgroundColor: "transparent", fontSize: "16px", padding: "12px" }} onClick={closeModal3}><i className="fas fa-times"></i></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group mb-3">
                            <label>{translate(lang, 'custodyCode')}</label>
                            <p className="mb-0"><strong>{postData.custody_code}</strong></p>
                        </div>
                        <div className="form-group mb-3">
                            <label>{translate(lang, 'customer')}</label>
                            <p className="mb-0"><strong>{postData.customer_name}<br />{postData.customer_phone}</strong></p>
                        </div>
                        <div className="form-group mb-3">
                            <label>{translate(lang, 'item')}</label>
                            <p className="mb-0"><strong>{postData.quantity} {translate(lang, "of")} {postData.uom_desc} {postData.item}</strong></p>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="input-remark">{translate(lang, 'remark')}</label>
                            <input id="input-remark" type="text" className="form-control" name="remark" onChange={(e) => modalInputOnchange(e)} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-success" onClick={submitForm3}>{translate(lang, 'submit')}</button>
                        <button className="btn btn-danger" onClick={closeModal3}>{translate(lang, 'cancel')}</button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isModal4Open}
                onRequestClose={closeModal4}
                contentLabel="Modal 4"
                style={customStyles}
                className={"modal-dialog"}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <button className="close" style={{ border: "0", backgroundColor: "transparent", fontSize: "16px", padding: "12px" }} onClick={closeModal4}><i className="fas fa-times"></i></button>
                        <h4 className="m-0"><i className="fas fa-info-circle"></i> {translate(lang, "customerParticulars")}</h4>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <span>{translate(lang, "name")}: <strong>{customerData.customer_name}</strong></span>
                        </div>
                        <div className="mb-3">
                            <span>{translate(lang, "phone")}: <strong>{customerData.customer_phone}</strong></span>
                        </div>
                        <div className="mb-3">
                            <span>{translate(lang, "email")}: <strong>{customerData.customer_email}</strong></span>
                        </div>
                        <div className="mb-3">
                            <span>{translate(lang, "custRef")}: <strong>{customerData.customer_reference}</strong></span>
                        </div>
                        <div className="mb-3">
                            <span>{translate(lang, "remark_1")}: <strong>{customerData.remark}</strong></span>
                        </div>
                        <div className="mb-3">
                            <span>{translate(lang, "remark_2")}: <strong>{customerData.remark2}</strong></span>
                        </div>
                        <div className="mb-3">
                            <span>{translate(lang, "remark_3")}: <strong>{customerData.remark3}</strong></span>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isModal5Open}
                onRequestClose={closeModal5}
                contentLabel="Modal 5"
                style={customStyles}
                className={"modal-dialog"}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <button className="close" style={{ border: "0", backgroundColor: "transparent", fontSize: "16px", padding: "12px" }} onClick={closeModal5}><i className="fas fa-times"></i></button>
                        <h4 className="m-0"><i className="fas fa-info-circle"></i> {translate(lang, "remind")}</h4>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <span>{translate(lang, "sendTo")} {customerData.customer_phone}</span>
                        </div>
                        <hr />
                        <div className="mb-3">
                            {TextWithLineBreaks(customerData.sms_body)}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-success" onClick={submitForm4} disabled={(disableButton) ? disableButton : ""}>{translate(lang, 'sendNow')}</button>
                        <button className="btn btn-danger" onClick={closeModal5}>{translate(lang, 'cancel')}</button>
                    </div>
                </div>
            </Modal>
            {/* MODAL */}
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CustodyList);