const en = {
    error: "Error",
    success: "Success",
    employed: "Employed",
    nonEmployed: "Non-Employed",
    loading: "Loading",
    dashboard: "Dashboard",
    memberList: "Member List",
    memberPolicy: "Member Policy",
    noResultFound: "No Result Found",
    goToPage: "Go To Page",
    page: "Page",
    show: "Show",
    searchHere: "Search here",
    searchTable: "Search Table",
    exportExcel: "Export Excel",
    index: "No.",
    name: "Name",
    fullName: "Full Name",
    age: "Age",
    identityCard: "Identity Card",
    birthDate: "Birth Date",
    phoneCode: "Phone Code",
    annualIncome: "Annual Income",
    monthlyCommitment: "Monthly Commitment",
    retireeIncomeMonthly: "Retiree Monthly Income",
    phone: "Phone",
    email: "Email",
    gender: "Gender",
    action: "Action",
    agent: "Agent",
    addAgent: "Add Agent",
    relationship: "Relationship",
    male: "Male",
    female: "Female",
    status: "Status",
    policyName: "Policy Name",
    policyNumber: "Policy Number",
    totalSumAssured: "Total Sum Assured",
    premiumAssured: "Premium Assured",
    premiumAmount: "Premium Amount",
    commencementDate: "Commencement Date",
    premiumPaymentTerm: "Premium Payment Term",
    policyTerms: "Policy Terms",
    expireDate: "Expire Date",
    assuredName: "Assured Name",
    remark: "Remark",
    addEvent: "Add Event",
    custody: "Custody",
    custodyList: "Custody List",
    eventList: "Event List",
    category: "Category",
    product: "Product",

    // nav
    member: "Member",
    list: "List",
    policy: "Policy",
    settings: "Settings",
    masterPolicy: "Master Policy",
    policyCategory: "Policy Category",
    rider: "Rider",
    questionnaire: "Questionnaire",
    event: "Event",
    user: "User",
    category1: "Category 1",
    category2: "Category 2",
    uom: "UOM",
    misc: "MISC",
    // end nav

    // button
    create: "Create",
    edit: "Edit",
    delete: "Delete",
    back: "Back",
    // end button

    masterPolicySettings: "Master Policy Settings",
    policyCategorySettings: "Policy Category Settings",
    term: "Term",
    code: "Code",
    displayType: "Display Type",
    level: "Level",
    detailsMasterPolicy: "Master Policy Details",
    createMasterPolicy: "Create Master Policy",
    editMasterPolicy: "Edit Master Policy",
    createPolicyCategory: "Create Policy Category",
    detailsPolicyCategory: "Policy Category Details",
    editPolicyCategory: "Edit Policy Category",
    masterPolicyForm: "Master Policy Form",
    policyCategoryForm: "Policy Category Form",
    detailsMemberPolicy: "Member Policy Details",
    createMemberPolicy: "Create Member Policy",
    editMemberPolicy: "Edit Member Policy",
    policyCategoryName: "Policy Category Name",
    type: "Type",
    description: "Description",
    icon: "Icon",
    currentIcon: "Current Icon",
    color: "Color",
    pin: "Pin",
    confirmPin: "Confirm Pin",
    createAgent: "Create Agent",
    editAgent: "Edit Agent",
    detailsAgent: "Details Agent",
    editMember: "Edit Member",
    createMember: "Add New Member",
    viewMember: "View Member",
    memberPolicyForm: "Member Policy Form",
    profile: "Profile",
    currentProfile: "Current Profile",
    assignedMemberList: "Assigned Member List",
    noMemberAssignedToThisAgent: "No member assigned to this agent.",
    nominationList: "Nomination List",
    allocationPercentage: "Allocation Percentage",
    contactNumber: "Contact Number",
    noNominationAssigned: "No Nomination Assigned.",
    father: "Father",
    mother: "Mother",
    guardian: "Guardian",
    child: "Child",
    createRider: "Create Rider",
    assignedPolicyCategory: "Assigned Policy Category",
    detailsRider: "Rider Details",
    editRider: "Edit Rider",
    policyRider: "Policy Rider",
    noPolicyFoundOnThisUser: "No Policy Found In This User",
    noPolicyRiderFoundOnThisUser: "No Policy Rider Found In This User",
    policyList: "Policy List",
    riderList: "Rider List",

    placeholderEnterHere: "Enter here",
    placeholderChoose: "Choose one option",
    ring: "Ring",
    barChart: "Bar Chart",
    horizontalBarChart: "Horizontal Bar Chart",
    merchant: "Merchant",
    merchantPolicy: "Merchant Policy",
    merchantPolicyList: "Merchant Policy List",
    merchantSettings: "Merchant Settings",
    merchantPolicySettings: "Merchant Policy Settings",
    merchantPolicyIcon: "Merchant Policy Icon",
    merchantName: "Merchant Name",
    merchantCode: "Merchant Code",
    merchantLogo: "Merchant Logo",
    currentCompanLogo: "Current Merchant Logo",
    createMerchant: "Create Merchant",
    createMerchantPolicy: "Create Merchant Policy",
    detailsMerchant: "Merchant Details",
    detailsMerchantPolicy: "Merchant Policy Details",
    editMerchant: "Edit Merchant",
    editMerchantPolicy: "Edit Merchant Policy",
    riderGroupName: "Rider Group Name",
    riderGroupIcon: "Rider Group Icon",
    currentRiderGroupIcon: "Current Rider Group Icon",
    addNewMerchantPolicy: "Add New Merchant Policy",
    totalSteps: "Total Steps",
    totalQuestions: "Total Questions",
    questionnaireList: "Questionnaire List",
    questionnaireForm: "Questionnaire Form",
    questionTitle: "Question Title",
    questionDescription: "Question Description",
    questionType: "Question Type",
    questionOptions: "Question Options",
    chooseOne: "Choose One",
    chooseMultiple: "Choose Multiple",
    fillIn: "Fill In",
    addNewQuestion: "Add New Question",
    addNewAnswer: "Add New Answer",
    question: "Question",
    eventName: "Event Name",
    eventImage: "Event Image",
    eventStart: "Event Start",
    eventEnd: "Event End",
    detailsEvent: "Event Detail",
    editEvent: "Edit Event",
    createEvent: "Create Event",
    noResult: "No Result(s)",
    btnFilter: "Filter",
    pleaseSelect: "-- PLEASE SELECT --",
    pending: "Pending",
    approved: "Approved",
    transactionDetail: "Transaction Detail",
    neverExpired: "Never Expired",
    // customerInfo: "Customer Info",
    // prodInfo: "Product Info",
    // prodQuantity: "Quantity",
    transactionDate: "Transaction Date",
    active: "Active",
    checkout: "Checkout",
    expired: "Expired",
    transfer: "Transfer",
    customerName: "Customer Name",
    productName: "Product Name",
    transactionCode: "Transaction Code",
    productList: "Product List",
    productCode: "Product Code",
    productImage: "Product Image",
    productUOM: "Product UOM",
    addProduct: "Add Product",
    editProduct: "Edit Product",
    createProduct: "Create Product",
    productDefaultExpiryPeriod: "Default Expiry Period",
    centimeter: "Centimeter",
    bottle: "Bottle",
    pint: "Pint",
    millimeter: "Millimeter",
    unit: "Unit",
    days: "Days",
    weeks: "Weeks",
    months: "Months",
    years: "Years",
    expiredAfter: "Expired After...",
    productDefaultExpiryUnitHelp: "Leave blank if you do not need default expiry period",
    select: "Select",
    dontHaveTemplate: "Don't have template?",
    changePassword: "Change Password",
    changePasswordHelp: "* Do not share your password with any other person",
    changeApprovalCode: "Change Approval Code",
    changeApprovalCodeHelp: "* Approval Code is required for Bartender to authorize custody",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    currentCode: "Current Code",
    newCode: "New Code",
    confirmCode: "Confirm Code",
    createCustody: "Create Custody",
    viewCustody: "View Custody",
    custodyCode: "Custody Code",
    customer: "Customer",
    customerReference: "Customer Reference",
    remark_1: "Remark 1",
    remark_2: "Remark 2",
    remark_3: "Remark 3",
    balance: "Balance",
    custodyDate: "Custody Date",
    expiryDate: "Expiry Date",
    expiryPeriod: "Expiry Period",
    expiredOn: "Expired On...",
    optional: "Optional",
    customerNameorPhone: "Name or Phone",
    dateJoined: "Date Joined",
    editCustomer: "Edit Customer",
    createCustomer: "Create Customer",
    addCustomer: "Add Customer",
    position: "Position",
    report: "Report",
    dateStart: "Date Start",
    dateEnd: "Date End",
    exportPDF: "Export PDF",
    exportExcel: "Export Excel",
    sortBy: "Sort by",
    merchantProfile: "Merchant Profile",
    merchantCode: "Merchant Code",
    merchantAddress: "Merchant Address",
    backgroundPhoto: "Background Photo",
    update: "Update",
    addUser: "Add User",
    editUser: "Edit User",
    createUser: "Create User",
    password: "Password",
    userType: "User Type",
    loginID: "Login ID",
    addCategory: "Add Category",
    editCategory: "Edit Category",
    createCategory: "Create Category",
    addUOM: "Add UOM",
    search: "Search",
    remove: "Remove",
    extend: "Extend",
    extendCustody: "Extend Custody",
    item: "Item",
    currentExpiryDate: "Current Expiry Date",
    extendDuration: "Extend Duration",
    submit: "Submit",
    cancel: "Cancel",
    bartender: "Bartender",
    quantity: "Quantity",
    checkOutBy: "Check Out By",
    transferBy: "Transfer By",
    transferToInventory: "Transfer To Inventory",
    custRef: "Cust Ref",
    customerParticulars: "Customer Particulars",
    transactionType: "Transaction Type",
    createdBy: "Created By",
    approvalCodeNotRequired: "*Approval Code is not required.",
    approvalCode: "Approval Code",
    totalCustody: "Total Custody",
    totalExpiredCustody: "Total Expired Custody",
    totalFollowers: "Total Followers",
    custodyIn: "Custody In",
    upcomingExpireCustody: "Upcoming Expire Custody",
    custodyCheckout: "Custody Checkout",
    follower: "Follower",
    fromShop: "From Shop",
    fromMobileApp: "From Mobile App",
    dateOfBirth: "Date of Birth",

    // form error message
    errorRequired: "This is required",
    internalServerError: "Internal server error, please contact Admin",
    pinNotMatch: "Pin not match",
    noItemFound: "No item found",
    nominationPercentageOverLimit: "Total Allocation Percentage Over 100%",
    noQuestionFound: "No Question Found.",
    noAnswerOptionFound: "No Answer Option Found.",
    errorExpiryDate: "Expiration date cannot be earlier than custody date.",
    errorPasswordNotMatch: "Confirm password not match with new password",
    errorExtendCustody: "Unable to extend as this custody will never expired",
    errorSomethingWentWrong: "Something went wrong. Please try again later.",
    errorQuantityExceed: "Quantity cannot exceed",

    // confirmation box message
    deleteConfirmation: "Delete Confirmation Box",
    deleteMessage: "Are you sure to remove this? Action cannot be undone.",

    references: "References",
    addNewReferences: "Add New References",
    noReferenceFound: "No References Found",
    referenceType: "Reference Type",
    referenceImageFileTypeHint: "Reference Image Only support these file type: jpg, jpeg, png.",
    noReferenceFileUploaded: "No Reference File uploaded",
    currentReferenceDocument: "Current Reference Document",

    // language
    en: "EN",
    cn: "CN",
    bm: "BM",
    english: "English",
    chinese: "Chinese",
    melayu: "Bahasa Melayu",

    date_format: "YYYY-MM-DD",
    date_time_format: "YYYY-MM-DD HH:mm",

    //options
    url: "URL",
    image: "Image",

    pleaseWait: "请稍等",
}

export default en;