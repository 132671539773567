import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import { translate } from "../../utils/translate";
import { getDashboard, getChart } from "../../services/ApiService";
import { Bar } from "react-chartjs-2";
import Select from 'react-select';

import total_custody_icon from "../../images/total_custody_icon.png";
import total_expired_custody_icon from "../../images/total_expired_custody_icon.png";
import total_followers_icon from "../../images/total_followers_icon.png";

const Dashboard = (props) => {
    const lang = props.lang;
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState([]);
    const [followerChartData, setFollowerChartData] = useState([]);
    const [custodyInData, setCustodyInData] = useState([]);
    const [custodyOutData, setCustodyOutData] = useState([]);
    const [upcomingExpiyCustodyData, setUpcomingExpiyCustodyData] = useState([]);
    const history = useHistory();

    const filterGroupData = [
        { value: "day", label: "Today", },
        { value: "week", label: "Week", },
        { value: "month", label: "Month", },
        { value: "year", label: "Year", }
    ];

    const onChangeSelect = async (e, name) => {
        let params = {
            chart_type: name,
            type: e.value,
        };

        let res = await getChart(params);

        if (res?.status === 'success') {
            if (!isObjectEmpty(res.data)) {
                generateChart(name, res.data);
            }
        }
    }

    useEffect(() => {
        getList();
        console.log('dashboard', props)
    }, [])

    async function getList() {
        setLoadingData(true);

        let res = await getDashboard();

        if (res?.status === 'success') {
            if (!isObjectEmpty(res.data.follower_data)) {
                generateChart("follower", res.data.follower_data);
            }
            if (!isObjectEmpty(res.data.custody_in_data)) {
                generateChart("custody_in", res.data.custody_in_data);
            }
            if (!isObjectEmpty(res.data.custody_out_data)) {
                generateChart("custody_out", res.data.custody_out_data);
            }
            if (!isObjectEmpty(res.data.upcoming_expire_custody_data)) {
                generateChart("upcoming_expire_custody", res.data.upcoming_expire_custody_data);
            }

            setData(res.data);

        } else {
        }
        setLoadingData(false);
    }

    const isObjectEmpty = (obj) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false; // If any property is found, the object is not empty
            }
        }
        return true; // No properties were found, so the object is empty
    };

    function generateChart(type, data) {
        let labelArr = [];
        let dataArr = [];

        if (!isObjectEmpty(data)) {
            Object.keys(data).forEach(key => {
                labelArr.push(data[key].label);
                dataArr.push(data[key].total);
            });

            if (type == 'follower') {
                setFollowerChartData(
                    {
                        labels: labelArr,
                        datasets: [
                            {
                                label: translate(lang, "follower"),
                                data: dataArr,
                                backgroundColor: '#6F00FE',
                            },
                        ],
                    }
                )
            } else if (type == 'custody_in') {
                setCustodyInData(
                    {
                        labels: labelArr,
                        datasets: [
                            {
                                label: translate(lang, "custodyIn"),
                                data: dataArr,
                                backgroundColor: '#6F00FE',
                            },
                        ],
                    }
                )
            } else if (type == 'custody_out') {
                setCustodyOutData(
                    {
                        labels: labelArr,
                        datasets: [
                            {
                                label: translate(lang, "custodyCheckout"),
                                data: dataArr,
                                backgroundColor: '#6F00FE',
                            },
                        ],
                    }
                )
            } else if (type == 'upcoming_expire_custody') {
                setUpcomingExpiyCustodyData(
                    {
                        labels: labelArr,
                        datasets: [
                            {
                                label: translate(lang, "upcomingExpireCustody"),
                                data: dataArr,
                                backgroundColor: '#6F00FE',
                            },
                        ],
                    }
                )
            }
        }
    }

    return (
        <>
            {loadingData
                ?
                <div className="loader-div loader-dasboard-div"><i className="fas fa-spinner fa-spin"></i></div>
                :
                <>
                    <div className="row">
                        <div className="col-xl-4 col-sm-6">
                            <div className="card total-custody-bg shadow-black card-bx">
                                <div className="card-body d-flex align-items-center">
                                    <div className="me-auto text-white">
                                        <div className="mb-3">
                                            <img src={total_custody_icon} alt={translate(props.lang, "totalCustody")} />
                                        </div>
                                        <h3 className="text-white">{(data.total_custody) ? data.total_custody : 0}</h3>
                                        <span>{translate(props.lang, "totalCustody")}</span>
                                    </div>
                                </div>
                                <div className="position-absolute" style={{ top: "10px", right: "10px" }}>
                                    <a onClick={() => history.push('/create_custody')} className="btn btn-sm btn-primary">{translate(lang, "createCustody2")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6">
                            <div className="card total-expired-custody-bg shadow-black card-bx">
                                <div className="card-body d-flex align-items-center">
                                    <div className="me-auto text-white">
                                        <div className="mb-3">
                                            <img src={total_expired_custody_icon} alt={translate(props.lang, "totalExpiredCustody")} />
                                        </div>
                                        <h3 className="text-white">{(data.total_upcoming_expired_custody) ? data.total_upcoming_expired_custody : 0}</h3>
                                        <span>{translate(props.lang, "totalExpiredCustody")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6">
                            <div className="card total-follower-bg shadow-black card-bx">
                                <div className="card-body d-flex align-items-center">
                                    <div className="me-auto text-white">
                                        <div className="mb-3">
                                            <img src={total_followers_icon} alt={translate(props.lang, "totalCustomers")} />
                                        </div>
                                        <h3 className="text-white">{(data.total_followers) ? data.total_followers : 0}</h3>
                                        <span>{translate(props.lang, "totalFollowers")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-6">
                            <div className="mb-5 custom-panel">
                                <div className="mb-3 d-flex justify-content-space-between align-items-center">
                                    <h5 className="float-start">{translate(lang, "custodyIn")}</h5>
                                    <div className="filter-chart float-end">
                                        <Select
                                            className="custom-form-control"
                                            closeMenuOnSelect={true}
                                            defaultValue={[filterGroupData[0]]}
                                            options={filterGroupData}
                                            onChange={(e) => onChangeSelect(e, "custody_in")}
                                        />
                                    </div>
                                </div>
                                <div className="custom-panel-body">
                                    <Bar
                                        data={custodyInData}
                                        options={{
                                            scales: {
                                                y: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-5 custom-panel">
                                <div className="mb-3 d-flex justify-content-space-between align-items-center">
                                    <h5 className="float-start">{translate(lang, "upcomingExpireCustody")}</h5>
                                    <div className="filter-chart float-end">
                                        <Select
                                            className="custom-form-control"
                                            closeMenuOnSelect={true}
                                            defaultValue={[filterGroupData[1]]}
                                            options={filterGroupData}
                                            onChange={(e) => onChangeSelect(e, "upcoming_expire_custody")}
                                        />
                                    </div>
                                </div>
                                <div className="custom-panel-body">
                                    <Bar
                                        data={upcomingExpiyCustodyData}
                                        options={{
                                            scales: {
                                                y: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-5 custom-panel">
                                <div className="mb-3 d-flex justify-content-space-between align-items-center">
                                    <h5 className="float-start">{translate(lang, "custodyCheckout")}</h5>
                                    <div className="filter-chart float-end">
                                        <Select
                                            className="custom-form-control"
                                            closeMenuOnSelect={true}
                                            defaultValue={[filterGroupData[0]]}
                                            options={filterGroupData}
                                            onChange={(e) => onChangeSelect(e, "custody_out")}
                                        />
                                    </div>
                                </div>
                                <div className="custom-panel-body">
                                    <Bar
                                        data={custodyOutData}
                                        options={{
                                            scales: {
                                                y: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-5 custom-panel">
                                <div className="mb-3 d-flex justify-content-space-between align-items-center">
                                    <h5 className="float-start">{translate(lang, "follower")}</h5>
                                    <div className="filter-chart float-end">
                                        <Select
                                            className="custom-form-control"
                                            closeMenuOnSelect={true}
                                            defaultValue={[filterGroupData[0]]}
                                            options={filterGroupData}
                                            onChange={(e) => onChangeSelect(e, "follower")}
                                        />
                                    </div>
                                </div>
                                <div className="custom-panel-body">
                                    <Bar
                                        data={followerChartData}
                                        options={{
                                            scales: {
                                                y: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(Dashboard);
